import {
  FinishedFetchingTypesAction,
  StartedFetchingTypesAction,
  WorkerAndWorkPlaceTypeActions,
  WorkerTypesAction,
  WorkPlaceTypesAction,
} from "./actions";
import IWorkerAndWorkPlaceTypeStore from "./store/Store";

const initialState: IWorkerAndWorkPlaceTypeStore = {
  workerTypes: [],
  workPlaceTypes: [],
  fetchingTypes: false,
};

type WorkerAndWorkPlaceTypeActionTypes =
  | WorkerTypesAction
  | WorkPlaceTypesAction
  | StartedFetchingTypesAction
  | FinishedFetchingTypesAction;

const workerAndWorkPlaceTypes = (
  /**
   * Redux state management and other boilerplate code is painful to manage and hinders discoverability.
   * Redux code guidelines show this bizarre pattern with initializing function arguments
   * without a default for the action. Example:
   * https://redux.js.org/usage/structuring-reducers/initializing-state#single-simple-reducer
   * This is linted against via typical lint rules.
   * Unfortunately, redux recommends this, so we need to mark the lint rule as disabled until
   * we eliminate redux from the code base.
   * https://linear.app/clipboardhealth/issue/FEF-167/deprecate-redux
   */
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: WorkerAndWorkPlaceTypeActionTypes
): IWorkerAndWorkPlaceTypeStore => {
  switch (action.type) {
    case WorkerAndWorkPlaceTypeActions.UPDATE_WORKER_TYPES:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        workerTypes: action.payload,
      };

    case WorkerAndWorkPlaceTypeActions.UPDATE_WORK_PLACE_TYPES:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        workPlaceTypes: action.payload,
      };
    case WorkerAndWorkPlaceTypeActions.STARTED_FETCHING_TYPES:
      return {
        ...state,
        fetchingTypes: true,
      };
    case WorkerAndWorkPlaceTypeActions.FINISHED_FETCHING_TYPES:
      return {
        ...state,
        fetchingTypes: false,
      };

    default:
      return state;
  }
};

export default workerAndWorkPlaceTypes;
