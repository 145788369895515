import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import {
  DocumentDownloadStatusResponse,
  useGetDocumentDownloadStatus,
} from "./api/useGetDocumentDownloadStatus";
import { useEffect, useRef } from "react";
import { DownloadRequestStatus, DownloadResultType } from "./types/common";
import { showErrorToast } from "../lib/Notifications";
import { APP_V2_USER_EVENTS, logEvent } from "../lib/analytics";

interface StartPollingProps {
  requestedByUserId: string;
  documentDownloadId: string;
  onPollingSuccess?: (data: DocumentDownloadStatusResponse) => void;
}

interface PollingState {
  documentId: string;
  startTime: number;
}

export function usePollingDocumentDownloadRequest() {
  const POLLING_INTERVAL_IN_SECONDS = 3; // 3 seconds
  const POLLING_EXPIRY_TIME_IN_SECONDS = 60 * 5; // 5 minutes
  const LOCAL_STORAGE_KEY = "workerDocumentDownloadPollingRequest";
  const GENERIC_ERROR_MESSAGE =
    "An error occurred while preparing the document for download. Please try again.";
  const pollingTimeoutId = useRef<NodeJS.Timeout | undefined>(undefined);
  const { mutateAsync: getDocumentDownloadRequest } = useGetDocumentDownloadStatus();

  useEffect(() => {
    return () => {
      if (isDefined(pollingTimeoutId.current)) {
        clearInterval(pollingTimeoutId.current);
        pollingTimeoutId.current = undefined;
      }
    };
  }, []);

  function getLocalStorageKey(requestedByUserId: string) {
    return `${LOCAL_STORAGE_KEY}-${requestedByUserId}`;
  }

  function getPollingStateFromLocalStorage(requestedByUserId: string): PollingState {
    const pollingState = localStorage.getItem(getLocalStorageKey(requestedByUserId));
    return isDefined(pollingState) ? JSON.parse(pollingState) : undefined;
  }

  function setPollingStateInLocalStorage(documentDownloadId: string, requestedByUserId: string) {
    const pollingState: PollingState = {
      documentId: documentDownloadId,
      startTime: Date.now(),
    };
    localStorage.setItem(getLocalStorageKey(requestedByUserId), JSON.stringify(pollingState));
  }

  function clearPolling(requestedByUserId: string) {
    if (pollingTimeoutId.current) {
      clearInterval(pollingTimeoutId.current);
      pollingTimeoutId.current = undefined;
    }
    localStorage.removeItem(getLocalStorageKey(requestedByUserId));
  }

  function hasActiveDownloadRequest(requestedByUserId: string) {
    const pollingState = getPollingStateFromLocalStorage(requestedByUserId);
    if (pollingState) {
      const timeElapsed = (Date.now() - pollingState?.startTime) / 1000;
      if (timeElapsed > POLLING_EXPIRY_TIME_IN_SECONDS) {
        clearPolling(getLocalStorageKey(requestedByUserId));
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  async function pollDocumentStatus(props: StartPollingProps) {
    const { documentDownloadId, requestedByUserId, onPollingSuccess } = props;
    try {
      const response = await getDocumentDownloadRequest({ documentDownloadId });
      const status = response.data.attributes.status;

      if (isDefined(status)) {
        if (status === DownloadRequestStatus.COMPLETED) {
          clearPolling(requestedByUserId);
          if (isDefined(onPollingSuccess)) {
            onPollingSuccess(response);
          }
        } else if (status === DownloadRequestStatus.FAILED) {
          clearPolling(requestedByUserId);
          logEvent(APP_V2_USER_EVENTS.WORKER_DOCUMENTS_DOWNLOAD_ALERT_TRIGGERED, {
            alertType: DownloadResultType.ERROR,
            workerId: response?.data?.attributes?.workerUserId,
            workplaceId: response?.data?.attributes?.workplaceId,
            workplaceUserId: response?.data?.attributes?.requestedBy,
            selectedDocs: response?.data?.attributes?.documentList,
          });
          showErrorToast(
            "An error occurred while preparing the document for download. Please try again."
          );
        } else {
          if (isNullOrUndefined(pollingTimeoutId.current)) {
            const timeoutId = setInterval(
              () => pollDocumentStatus(props),
              POLLING_INTERVAL_IN_SECONDS * 1000
            );
            pollingTimeoutId.current = timeoutId;
          }
        }
      }
    } catch (error) {
      clearPolling(requestedByUserId);
      showErrorToast(GENERIC_ERROR_MESSAGE);
      return;
    }
  }

  async function startPolling(props: StartPollingProps) {
    const { documentDownloadId, requestedByUserId } = props;

    const pollingState = getPollingStateFromLocalStorage(requestedByUserId);

    if (isDefined(pollingState)) {
      const { startTime } = pollingState;
      const timeElapsed = (Date.now() - startTime) / 1000;

      if (timeElapsed < POLLING_EXPIRY_TIME_IN_SECONDS) {
        showErrorToast(
          "There is already a download in progress, please wait for it to complete before downloading again"
        );
        return;
      }
    }

    clearPolling(requestedByUserId);
    setPollingStateInLocalStorage(documentDownloadId, requestedByUserId);
    await pollDocumentStatus(props);
  }

  return {
    startPolling,
    hasActiveDownloadRequest,
    cancelPollingRequest: clearPolling,
  };
}
