import { Tooltip, Box, List, ListItem } from "@mui/material";

const toolTipMessages = `To send an invite for one type of shift (AM/PM/NOC) select the date, and then select the shift type you want on that date.
To add another shift type (AM/PM/NOC) on the same date, click the additional shift type while the day is still selected
You can add as many days and shift types to your invitation as you'd like by continuing to select additional days and shift types.
If you're sending more than one shift, you'll have a chance to review a list of shifts included in your invitation before you send them to the professional.
To remove an invitation you've already selected, select the day, and then the shift type that you want to remove from that day.`;

export function GroupInviteToolTip() {
  return (
    <Tooltip
      title={
        <List dense sx={{ whiteSpace: "pre-wrap", fontSize: "0.875rem" }}>
          {toolTipMessages.split("\n").map((message) => (
            <ListItem key={message}>{`• ${message}`}</ListItem>
          ))}
        </List>
      }
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "600px",
          },
        },
      }}
    >
      <Box
        sx={{
          bgcolor: "text.disabled",
          color: "background.paper",
          maxWidth: "fit-content",
          paddingLeft: 0.5,
          paddingRight: 0.5,
        }}
      >
        How do I select multiple days or shift types?
      </Box>
    </Tooltip>
  );
}
