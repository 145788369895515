import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StringParam, useQueryParam } from "use-query-params";
import {
  checkAndShowWorkerRatingCarouselModal,
  hideWorkerReviewsBanner,
  hideWorkerRatingCarouselModal,
  showWorkerRatingCarouselModal,
} from "src/modules/workerReviews";
import { logBannerClick, logBannerClosed } from "./logs";
import { SessionType } from "src/modules/interface";
import { Button } from "src/designsystem/Button/Button";
import { includes } from "lodash";
import { FACILITY_ROLES } from "src/constants/facility";
import { BannerProps } from "src/designsystem/Banner/Banner.type";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useWorkerReviewsBanner() {
  const { admin, userId, user, type, profile } = useSelector(
    (state: SessionType) => state.session || {}
  );
  const workerReviews = useSelector((state: SessionType) => state.workerReviews);
  const dispatch = useDispatch();
  const [showWorkerReviewsCarousel, setShowWorkerReviewsCarousel] = useQueryParam(
    "worker-reviews-carousel",
    StringParam
  );
  const { frequencyInDays } = useCbhFlag(CbhFeatureFlag.WORKER_RATINGS_CAROUSEL, {
    defaultValue: { frequencyInDays: undefined },
  });

  const handleWorkerRatingCarouselModalClosed = () => {
    hideWorkerRatingCarouselModal(dispatch, userId);
    setShowWorkerReviewsCarousel(undefined);
  };

  const handlerBannerClosed = () => {
    hideWorkerReviewsBanner(dispatch, userId);
    if (user) {
      logBannerClosed(userId, user._id, workerReviews.numberOfAwaitingWorkerReviews);
    }
  };

  const handleOpenModal = () => {
    showWorkerRatingCarouselModal(dispatch);
    if (user) {
      logBannerClick(userId, user._id, workerReviews.numberOfAwaitingWorkerReviews);
    }
  };

  useEffect(() => {
    // User with supervisor role (FACILITY_ROLES.DOCUMENT)
    // can not see the worker reviews modal.
    const shouldShowWorkerRatingCarouselModal =
      includes(user?.access, FACILITY_ROLES.ADMIN) ||
      includes(user?.access, FACILITY_ROLES.INVOICES) ||
      includes(user?.access, FACILITY_ROLES.SHIFT_MANAGEMENT);

    if (frequencyInDays && !admin && userId && shouldShowWorkerRatingCarouselModal) {
      checkAndShowWorkerRatingCarouselModal(
        dispatch,
        userId,
        !!showWorkerReviewsCarousel,
        frequencyInDays
      );
    }
  }, [frequencyInDays, admin, userId, user, showWorkerReviewsCarousel, dispatch]);

  const bannerProps: BannerProps = {
    title: (
      <b>
        You have {workerReviews.numberOfAwaitingWorkerReviews}{" "}
        {workerReviews.numberOfAwaitingWorkerReviews > 1 ? "workers" : "worker"} waiting to be
        rated!
      </b>
    ),
    description: (
      <>
        The more ratings you give, the more likely you are to be connected with highly rated
        workers.
      </>
    ),
    closable: true,
    action: (
      <Button variant="primary" onClick={handleOpenModal}>
        <b>Rate these workers now</b>
      </Button>
    ),
    onClose: handlerBannerClosed,
    type: "info",
    "data-testid": "worker-review-banner",
  };
  return {
    ...(workerReviews.workerReviewsBannerVisible && workerReviews.numberOfAwaitingWorkerReviews > 0
      ? { bannerProps }
      : {}),
    ...(frequencyInDays &&
    workerReviews.workerReviewsModalVisible &&
    userId &&
    profile &&
    user?._id &&
    !admin
      ? {
          modalProps: {
            workplaceId: userId,
            workplaceUserId: user._id,
            visible: workerReviews.workerReviewsModalVisible,
            onClose: handleWorkerRatingCarouselModalClosed,
            timezone: profile.tmz,
            actionBy: type,
            triggeredBy: workerReviews.triggeredBy,
          },
        }
      : {}),
  };
}
