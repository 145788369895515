import { UseQueryResult } from "@tanstack/react-query";
import { useGetQuery, UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

export const DEFAULT_PAGE_SIZE = 24;

export enum ShiftCategory {
  UPCOMING = "upcoming",
  IN_PROGRESS = "in_progress",
  PAST = "past",
  FAVORITE = "favorite",
}

/**
 * Describes the parameters for the /api/workplace/worker API.
 */
const workerDetailsAtWorkplaceRequestSchema = z.object({
  category: z.nativeEnum(ShiftCategory),
  search: z.string().optional(),
  facilityId: z.string(),
  pageSize: z.number(),
  pageToken: z.string().optional(),
});

/**
 * Describes the schema for the object that the /api/workplace/worker
 * API returns.
 */
const workerDetailsAtWorkplaceSchema = z.object({
  attendance: z.object({ completedShifts: z.number() }),
  id: z.string(),
  userId: z.string(),
  profileImageUrl: z.string(),
  profileImagefileStorageFileKey: z.string().nullish(),
  profileImagefileStorageRootFolder: z.string().nullish(),
  qualification: z.string(),
  matchingFacilityQualifications: z.array(z.string()),
  shifts: z.object({
    past: z.number(),
    inprogress: z.number(),
    upcoming: z.number(),
    lastShiftId: z.string().nullish(),
    lastShiftEnd: z.string().nullish(),
    lastShiftStart: z.string().nullish(),
    cancelled: z.number().nullish(),
    cancelledInLastMonth: z.number().nullish(),
    hasRecentCancellation: z.boolean().nullish(),
  }),
});

const workerDetailsAtWorkplaceResponseSchema = z.object({
  workers: z.array(workerDetailsAtWorkplaceSchema),
});

export type WorkerDetailsAtWorkplaceAPIResponseType = z.infer<
  typeof workerDetailsAtWorkplaceResponseSchema
>;
export type WorkerDetailsAtWorkplaceParams = z.input<typeof workerDetailsAtWorkplaceRequestSchema>;

export function useGetWorkerDetailsAtWorkplace(
  params: WorkerDetailsAtWorkplaceParams,
  options: UseGetQueryOptions<WorkerDetailsAtWorkplaceAPIResponseType> = {}
): UseQueryResult<WorkerDetailsAtWorkplaceAPIResponseType> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workplace/workers`,
    queryParams: params,
    requestSchema: workerDetailsAtWorkplaceRequestSchema,
    responseSchema: workerDetailsAtWorkplaceResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_DETAILS_AT_WORKPLACE_FAILRE,
    },
    ...options,
  });
}
