import { Alert, type AlertColor } from "@mui/material";
import { type CustomContentProps, useSnackbar } from "notistack";
import { forwardRef } from "react";

interface SnackbarToastProps extends CustomContentProps {
  onClick?: () => void;
}

export const SnackbarToast = forwardRef<HTMLDivElement, SnackbarToastProps>((props, ref) => {
  const { id, message, variant, style, onClick } = props;
  const alertSeverity: AlertColor = variant === "default" ? "info" : variant;
  const { closeSnackbar } = useSnackbar();
  return (
    <Alert
      ref={ref}
      style={style}
      id={String(id)}
      severity={alertSeverity}
      variant="filled"
      sx={{
        minWidth: {
          xs: "initial",
          md: 600,
        },
      }}
      onClose={() => {
        closeSnackbar(id);
      }}
      onClick={onClick}
    >
      {message}
    </Alert>
  );
});

SnackbarToast.displayName = "SnackbarToast";
