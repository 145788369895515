import { UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import pluralize from "pluralize";

type ShiftInviteMissingCoreDocumentsDialogProps = {
  modalState: UseModalState;
  selectedWorkerName: string;
  missingDocuments: string[];
};

export function ShiftInviteMissingCoreDocumentsDialog(
  props: ShiftInviteMissingCoreDocumentsDialogProps
) {
  const { modalState, selectedWorkerName, missingDocuments } = props;
  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => modalState.closeModal()}
      aria-labelledby="shift-invite-missing-core-document-dialog-title"
    >
      <DialogTitle>
        {selectedWorkerName} is missing their {missingDocuments.join(", ")}{" "}
        {pluralize("document", missingDocuments.length)}, and cannot be invited to a shift.
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={() => modalState.closeModal()}>
          Cancel Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
