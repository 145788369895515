import { DeploymentEnvironmentName } from "./types";

export enum EnvironmentVariableKeys {
  REACT_APP_ADMIN_APP_CLIENT_URL = "REACT_APP_ADMIN_APP_CLIENT_URL",
  REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID = "REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID",
  REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN = "REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN",
  REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY = "REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY",
  REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID = "REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID",
  REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT = "REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT",
  REACT_APP_API_MAIN_URL = "REACT_APP_API_MAIN_URL",
  REACT_APP_ATTENDANCE_SCORE_SERVICE_URL = "REACT_APP_ATTENDANCE_SCORE_SERVICE_URL",
  REACT_APP_BASE_API_URL = "REACT_APP_BASE_API_URL",
  REACT_APP_DOCUMENTS_SERVICE_REST_API_URL = "REACT_APP_DOCUMENTS_SERVICE_REST_API_URL",
  REACT_APP_DOCUMENTS_SERVICE_URL = "REACT_APP_DOCUMENTS_SERVICE_URL",
  REACT_APP_ENVIRONMENT_NAME = "REACT_APP_ENVIRONMENT_NAME",
  REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK = "REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK",
  REACT_APP_GOOGLE_MAP_API = "REACT_APP_GOOGLE_MAP_API",
  REACT_APP_HOME_HEALTH_API_URL = "REACT_APP_HOME_HEALTH_API_URL",
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID = "REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID",
  REACT_APP_LICENSE_MANAGER_SERVICE_URL = "REACT_APP_LICENSE_MANAGER_SERVICE_URL",
  REACT_APP_MUI_LICENSE_KEY = "REACT_APP_MUI_LICENSE_KEY",
  REACT_APP_PAYMENTS_API_URL = "REACT_APP_PAYMENTS_API_URL",
  REACT_APP_SENDBIRD_APP_ID = "REACT_APP_SENDBIRD_APP_ID",
  REACT_APP_SHIFT_REVIEW_API_URL = "REACT_APP_SHIFT_REVIEW_API_URL",
  REACT_APP_SHIFT_VERIFICATION_BASE_URL = "REACT_APP_SHIFT_VERIFICATION_BASE_URL",
  REACT_APP_SHIFTS_BFF_API_URL = "REACT_APP_SHIFTS_BFF_API_URL",
  REACT_APP_WORKER_ETA_API = "REACT_APP_WORKER_ETA_API",
  REACT_APP_WORKER_SERVICE_URL = "REACT_APP_WORKER_SERVICE_URL",
  REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS = "REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS",
  REACT_APP_WARMLY_SCRIPT_URL = "REACT_APP_WARMLY_SCRIPT_URL",
  REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK = "REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK",
}

type Environments = Record<DeploymentEnvironmentName, Record<EnvironmentVariableKeys, string>>;

const environmentConfigs: Environments = {
  [DeploymentEnvironmentName.LOCAL]: {
    REACT_APP_ADMIN_APP_CLIENT_URL:
      process.env.REACT_APP_ADMIN_APP_CLIENT_URL ??
      "https://admin-webapp.development.clipboardhealth.org",
    REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID:
      process.env.REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID ??
      "0df687d0-5112-40f3-a7e6-a591f9f5ec78",
    REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN:
      process.env.REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN ?? "pube1f1a3b7e8059aff58b447323e31a1f5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY:
      process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY ?? "f1a21129-7b8f-48ee-8988-c2e9fc7534c5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID:
      process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID ??
      "web.org.clipboardhealth.aws.admin-webapp.development",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT:
      process.env.REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT ?? "sdk.iad-05.braze.com",
    REACT_APP_API_MAIN_URL: process.env.REACT_APP_API_MAIN_URL ?? "http://localhost:5000",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      process.env.REACT_APP_ATTENDANCE_SCORE_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: process.env.REACT_APP_BASE_API_URL ?? "http://localhost:5000/api",
    REACT_APP_DOCUMENTS_SERVICE_REST_API_URL:
      process.env.REACT_APP_DOCUMENTS_SERVICE_REST_API_URL ??
      "https://apigateway.development.clipboardhealth.org/docs/api",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      process.env.REACT_APP_DOCUMENTS_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME:
      process.env.REACT_APP_ENVIRONMENT_NAME ?? DeploymentEnvironmentName.DEVELOPMENT,
    REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK:
      process.env.REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK ??
      "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities",
    REACT_APP_GOOGLE_MAP_API:
      process.env.REACT_APP_GOOGLE_MAP_API ?? "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL:
      process.env.REACT_APP_HOME_HEALTH_API_URL ??
      "https://home-health-api.development.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID:
      process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? "630f2bba0ae37a11f535a61e",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      process.env.REACT_APP_LICENSE_MANAGER_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/license-manager",
    REACT_APP_MUI_LICENSE_KEY:
      process.env.REACT_APP_MUI_LICENSE_KEY ??
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL:
      process.env.REACT_APP_PAYMENTS_API_URL ??
      "https://apigateway.development.clipboardhealth.org/payment",
    REACT_APP_SENDBIRD_APP_ID:
      process.env.REACT_APP_SENDBIRD_APP_ID ?? "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL:
      process.env.REACT_APP_SHIFT_REVIEW_API_URL ??
      "https://apigateway.development.clipboardhealth.org/reviews",
    REACT_APP_SHIFT_VERIFICATION_BASE_URL:
      process.env.REACT_APP_SHIFT_VERIFICATION_BASE_URL ??
      "https://shiftverification.development.clipboardhealth.org",
    REACT_APP_SHIFTS_BFF_API_URL:
      process.env.REACT_APP_SHIFTS_BFF_API_URL ??
      "https://apigateway.development.clipboardhealth.org/shifts-bff/admin",
    REACT_APP_WORKER_ETA_API:
      process.env.REACT_APP_WORKER_ETA_API ??
      "https://apigateway.development.clipboardhealth.org/worker-eta",
    REACT_APP_WORKER_SERVICE_URL:
      process.env.REACT_APP_WORKER_SERVICE_URL ??
      "https://apigateway.development.clipboardhealth.org/worker",
    REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS:
      process.env.REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS ?? "60",
    REACT_APP_WARMLY_SCRIPT_URL: "",
    REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK:
      process.env.REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK ??
      "https://clipboard.page.link/sign-new-agreement",
  },
  [DeploymentEnvironmentName.DEVELOPMENT]: {
    REACT_APP_ADMIN_APP_CLIENT_URL: "https://admin-webapp.development.clipboardhealth.org",
    REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID: "0df687d0-5112-40f3-a7e6-a591f9f5ec78",
    REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN: "pube1f1a3b7e8059aff58b447323e31a1f5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY: "f1a21129-7b8f-48ee-8988-c2e9fc7534c5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID: "web.org.clipboardhealth.aws.admin-webapp.development",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_API_MAIN_URL: "https://apigateway.development.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.development.clipboardhealth.org/api",
    REACT_APP_DOCUMENTS_SERVICE_REST_API_URL:
      "https://apigateway.development.clipboardhealth.org/docs/api",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.DEVELOPMENT,
    REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK:
      "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.development.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "630f2bba0ae37a11f535a61e",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.development.clipboardhealth.org/license-manager",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.development.clipboardhealth.org/payment",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.development.clipboardhealth.org/reviews",
    REACT_APP_SHIFT_VERIFICATION_BASE_URL:
      "https://shiftverification.development.clipboardhealth.org",
    REACT_APP_SHIFTS_BFF_API_URL:
      "https://apigateway.development.clipboardhealth.org/shifts-bff/admin",
    REACT_APP_WORKER_ETA_API: "https://apigateway.development.clipboardhealth.org/worker-eta",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.development.clipboardhealth.org/worker",
    REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS: "60",
    REACT_APP_WARMLY_SCRIPT_URL: "",
    REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK:
      process.env.REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK ??
      "https://clipboard.page.link/sign-new-agreement",
  },
  [DeploymentEnvironmentName.STAGING]: {
    REACT_APP_ADMIN_APP_CLIENT_URL: "https://admin-webapp.staging.clipboardhealth.org",
    REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID: "0df687d0-5112-40f3-a7e6-a591f9f5ec78",
    REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN: "pube1f1a3b7e8059aff58b447323e31a1f5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY: "f1a21129-7b8f-48ee-8988-c2e9fc7534c5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID: "web.org.clipboardhealth.aws.admin-webapp.development",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_API_MAIN_URL: "https://apigateway.staging.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.staging.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.staging.clipboardhealth.org/api",
    REACT_APP_DOCUMENTS_SERVICE_REST_API_URL:
      "https://apigateway.staging.clipboardhealth.org/docs/api",
    REACT_APP_DOCUMENTS_SERVICE_URL: "https://apigateway.staging.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.STAGING,
    REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK:
      "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.staging.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "60d9bf072e18760d739e5f9a",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.staging.clipboardhealth.org/license-manager",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.staging.clipboardhealth.org/payment",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.staging.clipboardhealth.org/reviews",
    REACT_APP_SHIFT_VERIFICATION_BASE_URL: "https://shiftverification.staging.clipboardhealth.org",
    REACT_APP_SHIFTS_BFF_API_URL: "https://apigateway.staging.clipboardhealth.org/shifts-bff/admin",
    REACT_APP_WORKER_ETA_API: "https://apigateway.staging.clipboardhealth.org/worker-eta",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.staging.clipboardhealth.org/worker",
    REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS: "60",
    REACT_APP_WARMLY_SCRIPT_URL: "",
    REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK:
      process.env.REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK ??
      "https://clipboard.page.link/sign-new-agreement",
  },
  [DeploymentEnvironmentName.PROD_SHADOW]: {
    REACT_APP_ADMIN_APP_CLIENT_URL: "https://admin-webapp.prod-shadow.clipboardhealth.org",
    REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID: "0df687d0-5112-40f3-a7e6-a591f9f5ec78",
    REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN: "pube1f1a3b7e8059aff58b447323e31a1f5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY: "f1a21129-7b8f-48ee-8988-c2e9fc7534c5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID: "web.org.clipboardhealth.aws.admin-webapp.prod-shadow",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_API_MAIN_URL: "https://apigateway.prod-shadow.clipboardhealth.org",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/attendance-policy",
    REACT_APP_BASE_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/api",
    REACT_APP_DOCUMENTS_SERVICE_REST_API_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/docs/api",
    REACT_APP_DOCUMENTS_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.PROD_SHADOW,
    REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK:
      "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.prod-shadow.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "6470bf875947cf12f734ff1d",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/license-manager",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/payment",
    REACT_APP_SENDBIRD_APP_ID: "F35ACEA9-2248-44DC-A448-73DCCB3FC17E",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://apigateway.prod-shadow.clipboardhealth.org/reviews",
    REACT_APP_SHIFT_VERIFICATION_BASE_URL:
      "https://shiftverification.prod-shadow.clipboardhealth.org",
    REACT_APP_SHIFTS_BFF_API_URL:
      "https://apigateway.prod-shadow.clipboardhealth.org/shifts-bff/admin",
    REACT_APP_WORKER_ETA_API: "https://apigateway.prod-shadow.clipboardhealth.org/worker-eta",
    REACT_APP_WORKER_SERVICE_URL: "https://apigateway.prod-shadow.clipboardhealth.org/worker",
    REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS: "60",
    REACT_APP_WARMLY_SCRIPT_URL: "",
    REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK:
      process.env.REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK ??
      "https://clipboard.page.link/sign-new-agreement",
  },
  [DeploymentEnvironmentName.PRODUCTION]: {
    REACT_APP_ADMIN_APP_CLIENT_URL: "https://clipboard.health",
    REACT_APP_ADMIN_DATADOG_RUM_APPLICATION_ID: "0df687d0-5112-40f3-a7e6-a591f9f5ec78",
    REACT_APP_ADMIN_DATADOG_RUM_CLIENT_TOKEN: "pube1f1a3b7e8059aff58b447323e31a1f5",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_KEY: "45e6e2cc-2bf5-44f3-9c65-06481190f5bf",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SAFARI_ID: "web.org.clipboardhealth.aws.admin-webapp.production",
    REACT_APP_ADMIN_HCF_WEB_BRAZE_SDK_ENDPOINT: "sdk.iad-05.braze.com",
    REACT_APP_API_MAIN_URL: "https://api.clipboard.health",
    REACT_APP_ATTENDANCE_SCORE_SERVICE_URL: "https://api.clipboard.health/attendance-policy",
    REACT_APP_BASE_API_URL: "https://api.clipboard.health/api",
    REACT_APP_DOCUMENTS_SERVICE_REST_API_URL: "https://api.clipboard.health/docs/api",
    REACT_APP_DOCUMENTS_SERVICE_URL: "https://api.clipboard.health/docs/graphql",
    REACT_APP_ENVIRONMENT_NAME: DeploymentEnvironmentName.PRODUCTION,
    REACT_APP_FACILITY_SUPPORT_ARTICLES_LINK:
      "https://support.clipboardhealth.com/hc/en-us/categories/4943215926167-Facilities",
    REACT_APP_GOOGLE_MAP_API: "AIzaSyA3PoA1BjIP1TGD4GiHZsBSOMqHeXE5HYE",
    REACT_APP_HOME_HEALTH_API_URL: "https://home-health-api.prod.clipboardhealth.org/api",
    REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: "60c75392e1cd230bf4f1ca30",
    REACT_APP_LICENSE_MANAGER_SERVICE_URL: "https://api.clipboard.health/license-manager",
    REACT_APP_MUI_LICENSE_KEY:
      "9d431ac8d7aa1f4b62a9d61432d0b4a1Tz03ODUyNCxFPTE3MzEyODg5NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    REACT_APP_PAYMENTS_API_URL: "https://api.clipboard.health/payment",
    REACT_APP_SENDBIRD_APP_ID: "7C7019BD-5389-48A2-8BCA-353823B917AF",
    REACT_APP_SHIFT_REVIEW_API_URL: "https://api.clipboard.health/reviews",
    REACT_APP_SHIFT_VERIFICATION_BASE_URL: "https://shiftverification.prod.clipboardhealth.org",
    REACT_APP_SHIFTS_BFF_API_URL: "https://api.clipboard.health/shifts-bff/admin",
    REACT_APP_WORKER_ETA_API: "https://api.clipboard.health/worker-eta",
    REACT_APP_WORKER_SERVICE_URL: "https://api.clipboard.health/worker",
    REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS: "60",
    REACT_APP_WARMLY_SCRIPT_URL:
      "https://opps-widget.getwarmly.com/warmly.js?clientId=23010ecd74224a4bf059e5f9b8abdfe8",
    REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK:
      process.env.REACT_APP_HCP_SIGN_NEW_AGREEMENT_DEEPLINK ??
      "https://cbh.care/sign-new-agreement",
  },
};

export function getEnvironmentConfig(environmentName: DeploymentEnvironmentName) {
  const selectedEnvironment = environmentConfigs[environmentName];
  return selectedEnvironment ?? environmentConfigs[DeploymentEnvironmentName.PRODUCTION];
}

export const environmentConfig = getEnvironmentConfig(
  process.env.REACT_APP_ENVIRONMENT_NAME as DeploymentEnvironmentName
);
