import { Input, Dropdown } from "antd";
import "./accountSwitcher.css";
import { useAccountSwitcherHook } from "./useAccountSwitcherHook";
import { AccountSwitcherMenu } from "./AccountSwitcherMenu";

const { Search } = Input;

function AccountSwitcher({ className, adminUserId, pseudo, closeMenu }) {
  const {
    handleChange,
    agentDetails,
    fetchAgentList,
    facilityDetails,
    fetchFacilityList,
    handleOnDropdownVisible,
    accountsListVisible,
    setAccountsListVisible,
    searchAccounts,
  } = useAccountSwitcherHook({ pseudo, closeMenu });

  return (
    <Dropdown
      overlay={() => (
        <AccountSwitcherMenu
          handleChange={handleChange}
          adminUserId={adminUserId}
          agentDetails={agentDetails}
          fetchAgentList={fetchAgentList}
          facilityDetails={facilityDetails}
          fetchFacilityList={fetchFacilityList}
        />
      )}
      trigger={["click"]}
      overlayClassName="account-dropdown"
      onVisibleChange={handleOnDropdownVisible}
      visible={accountsListVisible}
    >
      <Search
        placeholder="Select Account"
        className={className + " account-switcher"}
        data-testid="account-switcher"
        onChange={(e) => {
          setAccountsListVisible(true);
          searchAccounts(e.target.value);
        }}
        loading={agentDetails.loading || facilityDetails.loading}
      />
    </Dropdown>
  );
}

export { AccountSwitcher };
