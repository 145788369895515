import { LicenseData } from "src/interface";
import { workerAndWorkPlaceTypesProvider } from "../workers/workerAndWorkPlaceTypesProvider";
import { WorkerTypes as DefaultWorkerTypes } from "../workers/WorkerTypes";
import { WorkPlaceTypes } from "../workers/WorkPlaceTypes";
import { uniq } from "lodash";

const { workerTypes } = workerAndWorkPlaceTypesProvider;
const { workerTypeDefaults } = workerAndWorkPlaceTypesProvider;
const workerTypesRates = (rates = workerTypeDefaults) =>
  workerTypes?.map((type) => `$${rates[type] || 0}`).join(", ");
const workerSpecialities = [
  "General Surgery",
  "Thoracic Surgery",
  "Colon and Rectal Surgery",
  "Obstetrics and Gynecology",
  "Gynecologic Oncology",
  "Neurological Surgery",
  "Ophthalmic Surgery",
  "Oral Surgery and Maxillofacial Surgery",
  "Minor Orthopaedic Surgery",
  "Total Orthopaedic Surgery",
  "Otolaryngology (ENT)",
  "Pediatric Surgery",
  "Plastic Surgery",
  "Urology",
  "Vascular Surgery",
];
const newWorkerTypes =
  workerAndWorkPlaceTypesProvider.workPlaceTypes[WorkPlaceTypes.SURGERY_CENTER] || [];
const allWorkerTypes = workerAndWorkPlaceTypesProvider.workerTypes?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));

const { workerTypeObj } = workerAndWorkPlaceTypesProvider;
const WorkerTypes = workerAndWorkPlaceTypesProvider.workerTypeObj;
const FacilityWorkerType = workerAndWorkPlaceTypesProvider.workPlaceTypes;
/**
 * FIXME Rename to `WorkerStages`
 */
enum WORKER_STAGES_ENUM {
  ONBOARDING = "ONBOARDING",
  ENROLLED = "ENROLLED",
  PROBATION = "PROBATION",
  RESTRICTED = "RESTRICTED",
  DEACTIVATED = "DEACTIVATED",
  DELETION_REQUESTED = "DELETION_REQUESTED",
  SOFT_DELETED = "SOFT_DELETED",
}

const getWorkerStageLabel = (stage: WORKER_STAGES_ENUM) => {
  if (stage === WORKER_STAGES_ENUM.SOFT_DELETED) {
    return "Deleted";
  }

  return stage;
};

type FAILED_WORKER_STAGES =
  | WORKER_STAGES_ENUM.PROBATION
  | WORKER_STAGES_ENUM.RESTRICTED
  | WORKER_STAGES_ENUM.DEACTIVATED;

const WORKER_STAGES_REASONS_ENUM: Record<FAILED_WORKER_STAGES, Record<string, string>> = {
  [WORKER_STAGES_ENUM.PROBATION]: {
    ATTENDANCE_AND_TIMELINE: "ATTENDANCE_AND_TIMELINE",
    PROFESSIONAL_ETIQUETTE: "PROFESSIONAL_ETIQUETTE",
    CLINICAL_HEALTHCARE_TRAINING: "CLINICAL_HEALTHCARE_TRAINING",
    BEDSIDE_MANNER: "BEDSIDE_MANNER",
    OTHER: "OTHER",
    NCNS: "NCNS",
  },
  [WORKER_STAGES_ENUM.RESTRICTED]: {
    ATTENDANCE_ISSUES: "ATTENDANCE_ISSUES",
    EXCESSIVE_DNRS: "EXCESSIVE_DNRS",
    OTHER: "OTHER",
  },
  [WORKER_STAGES_ENUM.DEACTIVATED]: {
    ATTENDANCE_ISSUES: "ATTENDANCE_ISSUES",
    EXCESSIVE_DNRS: "EXCESSIVE_DNRS",
    FAILED_BACKGROUND_CHECK: "FAILED_BACKGROUND_CHECK",
    FRAUD_SIGN_UP: "FRAUD_SIGN_UP",
    HCP_CHOSE_TO_DEACTIVATE: "HCP_CHOSE_TO_DEACTIVATE",
    MAJOR_INCIDENT: "MAJOR_INCIDENT",
    MINOR_INCIDENT: "MINOR_INCIDENT",
    TIMESHEET_FRAUD: "TIMESHEET_FRAUD",
    DUPLICATE_SIGNUP: "DUPLICATE_SIGNUP",
  },
};
const WORKER_STAGES_REASONS = {
  [WORKER_STAGES_ENUM.PROBATION]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION]),
  ],
  [WORKER_STAGES_ENUM.RESTRICTED]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.RESTRICTED]),
  ],
  [WORKER_STAGES_ENUM.DEACTIVATED]: [
    ...Object.values(WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED]),
  ],
};
const WORKER_STAGES_REASONS_DATA = {
  [WORKER_STAGES_ENUM.PROBATION]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].ATTENDANCE_AND_TIMELINE]: {
      description: "Attendance & Timeliness Issues",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].PROFESSIONAL_ETIQUETTE]: {
      description: "Professional Etiquette Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].CLINICAL_HEALTHCARE_TRAINING]: {
      description: "Clinical & Healthcare Training Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].BEDSIDE_MANNER]: {
      description: "Bedside Manner Needs Improvement",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].OTHER]: {
      description: "Other Complaints, please explain",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.PROBATION].NCNS]: {
      description: "No show",
      mandatoryNote: false,
    },
  },
  [WORKER_STAGES_ENUM.RESTRICTED]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.RESTRICTED].ATTENDANCE_ISSUES]: {
      description: "Attendance Issues",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.RESTRICTED].EXCESSIVE_DNRS]: {
      description: "Excessive DNRs (Not Safety Related)",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.RESTRICTED].OTHER]: {
      description: "Other",
      mandatoryNote: true,
    },
  },
  [WORKER_STAGES_ENUM.DEACTIVATED]: {
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].ATTENDANCE_ISSUES]: {
      description: "Attendance issues",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].EXCESSIVE_DNRS]: {
      description: "Excessive Do-Not-Return requests",
      mandatoryNote: true,
    },

    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].FAILED_BACKGROUND_CHECK]: {
      description: "Failed background check",
      mandatoryNote: false,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].FRAUD_SIGN_UP]: {
      description: "Fraud sign up",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].HCP_CHOSE_TO_DEACTIVATE]: {
      description: "HCP chose to deactivate",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].MAJOR_INCIDENT]: {
      description: "Major incident",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].MINOR_INCIDENT]: {
      description: "Minor incident",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].TIMESHEET_FRAUD]: {
      description: "Timesheet fraud",
      mandatoryNote: true,
    },
    [WORKER_STAGES_REASONS_ENUM[WORKER_STAGES_ENUM.DEACTIVATED].DUPLICATE_SIGNUP]: {
      description: "Duplicate Signup",
      mandatoryNote: true,
    },
  },
};

const hcpNamAssignmentCases = {
  NEAREST_FACILITY: "NEAREST_FACILITY",
  LEGACY_FACILITY: "LEGACY_FACILITY",
  NO_LOCATION: "NO_LOCATION",
  NO_NEAREST_FACILITY: "NO_NEAREST_FACILITY",
  MANUAL: "MANUAL",
};

const hcpNamAssignmentCaseList = Object.values(hcpNamAssignmentCases);

const workerTypesOptions = workerAndWorkPlaceTypesProvider.workerTypes
  .filter(
    (workerType) =>
      !workerAndWorkPlaceTypesProvider.workPlaceTypes[WorkPlaceTypes.SURGERY_CENTER]?.includes(
        workerType
      )
  )
  ?.map((workerType) => ({
    label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
    value: workerType,
  }));
const medicalLabWorkerOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.MEDICAL_LAB
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));
const surgicalWorkerTypesOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.SURGERY_CENTER
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));

const medicalLabWorkerTypes = medicalLabWorkerOptions?.map((type) => type.value);

const HHCAndHospiceWorkerTypeOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.HOME_HEALTHCARE
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));

const HospitalWorkerOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.HOSPITAL
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));
const getWorkerShiftTypeLabel = (shiftType) => {
  if (!shiftType) {
    return;
  }
  return shiftType === DefaultWorkerTypes.LVN
    ? "LVN/LPN"
    : shiftType.toUpperCase().replace(/_/g, " ");
};

const psychHospitalWorkerTypesOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.PSYCH_HOSPITAL
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));
const longTermCareWorkerTypesOptions = workerAndWorkPlaceTypesProvider.workPlaceTypes[
  WorkPlaceTypes.LONG_TERM_CARE
]?.map((workerType) => ({
  label: workerType === DefaultWorkerTypes.LVN ? "LVN / LPN" : workerType,
  value: workerType,
}));

const getWorkerQualifications = (licensesData: LicenseData[] | null) => {
  /**
   * FIXME - licensesData appears to always be defined.
   * We should be able to remove `null` type here.
   * https://github.com/ClipboardHealth/clipboard-health/blob/main/projects/backend-main/src/models/agentProfile.type.ts#L317
   */
  if (!licensesData) {
    return [];
  }
  const qualifications: string[] = [];
  for (const data of licensesData) {
    qualifications.push(data.qualification);
  }
  return uniq(qualifications.sort());
};

const workerPreferences = {
  QUALIFICATION: "qualification",
  DISTANCE: "distance",
};

export {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
  workerPreferences,
  getWorkerQualifications,
  getWorkerStageLabel,
};
