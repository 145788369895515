import { Fragment, ReactElement, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { Channel, useSendbirdStateContext } from "@sendbird/uikit-react";
import "@sendbird/uikit-react/dist/index.css";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import "./chatStyle.scss";
import { fetchAndSetUpcomingShifts, updateChannel } from "src/modules/chat";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { ChatHeaderProps, ChatModalProps, ChatProviderProps } from "./chat.types";
import { logProfileNavigated } from "src/containers/workers/logs";
import { WhoCalledRatingPerformanceModal } from "src/components/WorkerRatingPerformanceModal/workerReview.types";
import { useQuery } from "@tanstack/react-query";
import { fetchExclusion } from "src/api/workerReview";
import { getLocation } from "src/utils/routes";
import { SendbirdChat, Session, SessionType } from "src/modules/interface";
import { useActiveChatWindowDays } from "./useActiveChatWindowDays";
import { getLastMessageDate } from "src/utils/sendbird";
import {
  fetchChannels,
  getChannelShiftTime,
  getChannelTitle,
  getLastMessageInfo,
  getMessageInputDefinition,
} from "./channel";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { BaseChannel } from "@sendbird/chat";
import { SendableMessageType } from "@sendbird/uikit-react/types/utils";
import { FileMessageCreateParams, UserMessageCreateParams } from "@sendbird/chat/message";
import { renderSendbirdChannelMessage } from "./ChannelMessage";
import { ShiftInviteDialog } from "src/containers/Shift/ShiftInvites/Dialogs/index";
import { useModalState } from "@clipboard-health/ui-react";
import { useWorker } from "@src/appV2/Worker/api/useWorker";
import { useShiftInfo } from "./utils/useShiftInfo";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, Button, Tooltip } from "@mui/material";
import { StreakIndicator } from "@src/appV2/Worker/StreakIndicator/StreakIndicator";
import { useIsStreaksPreRewardsEnabled } from "@src/appV2/Worker/api/useIsStreaksPreRewardsEnabled";
import { StreakStatus, useGetFacilityStreaks } from "@src/appV2/Worker/api/useGetFacilityStreaks";
import { MONGO_DB_ID_LENGTH } from "@src/constants/database";
import { isFavoritedReason } from "../workers/ListWorkers/utils";
import { usePreferredWorker } from "@src/containers/workplace/WorkerDetails/usePreferredWorker";
import { FavoriteIndicator } from "@src/appV2/Worker/FavoriteIndicator/FavoriteIndicator";
import { useWorkplaceWorker } from "@src/containers/workers/WorkerDetails/useWorkplaceWorker";
import {
  ShiftCategory,
  useGetWorkerDetailsAtWorkplace,
  DEFAULT_PAGE_SIZE,
} from "@src/appV2/FacilityWorkers/api/useGetWorkerDetailsAtWorkplace";
import { isEmpty } from "lodash";
import { environmentConfig } from "@src/appV2/environment";
import { FacilityStatusObj } from "@src/utils/facility";
import { PENDING_ACCOUNT_TOOLTIP_MESSAGE } from "@src/appV2/Calendar/constants";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const appId = environmentConfig.REACT_APP_SENDBIRD_APP_ID;

function ChatHeader(props: ChatHeaderProps) {
  const { workerId, workplaceId, workplaceUserId, currentChannel, backAction, facilityStatus } =
    props;
  const editShiftInviteModalState = useModalState();
  const { data: worker, isSuccess: isWorkerSuccess } = useWorker(workerId);
  const shiftInfo = useShiftInfo(workplaceId);
  const history = useHistory();

  const handleWorkerNameClicked = (withNavigation: boolean) => {
    if (withNavigation) {
      history.push(getLocation("workplaceWorkerDetails", { pathParams: { workerId } }));
    }
    logProfileNavigated({
      workerId,
      workplaceId,
      workplaceUserId,
      location: WhoCalledRatingPerformanceModal.CHAT_CHANNEL,
    });
  };

  const isStreaksPreRewardsEnabled = useIsStreaksPreRewardsEnabled();

  const streaksQueryResults = useGetFacilityStreaks(
    {
      facilityId: workplaceId,
      filter: { agentIds: [workerId] },
    },
    {
      enabled:
        isStreaksPreRewardsEnabled &&
        isDefined(workplaceId) &&
        workplaceId.length === MONGO_DB_ID_LENGTH &&
        workerId.length === MONGO_DB_ID_LENGTH,
    }
  );

  const currentStreak = streaksQueryResults.isSuccess
    ? streaksQueryResults.data.data.find(
        ({ attributes }) => attributes.status === StreakStatus.ACTIVE
      )
    : undefined;

  const isFavoriteFlagEnabled = useCbhFlag(CbhFeatureFlag.PRIORITY_ACCESS, {
    defaultValue: {
      "*": false,
    },
  })?.["*"];

  const { data: preferredWorker } = usePreferredWorker(workplaceId, workerId);

  const { exclusion } = useWorkplaceWorker({
    workerId,
    workplaceId,
    workplaceUserId,
  });

  const workerIsFavorite = isFavoritedReason(preferredWorker?.reason);

  const workerDetails = useGetWorkerDetailsAtWorkplace(
    {
      category: workerIsFavorite ? ShiftCategory.FAVORITE : ShiftCategory.PAST,
      search: worker?.name,
      facilityId: workplaceId,
      pageSize: DEFAULT_PAGE_SIZE,
    },
    {
      enabled:
        isStreaksPreRewardsEnabled &&
        isWorkerSuccess &&
        isDefined(worker?.name) &&
        !isEmpty(worker?.name) &&
        isDefined(workplaceId),
    }
  );

  // TODO: filtering will be refactored out after https://linear.app/clipboardhealth/issue/CS-3027/add-an-optional-list-of-workerids-to-the-query-params-for-the-endpoint is complete
  const currentWorkerDetails = workerDetails.isSuccess
    ? workerDetails.data.workers.find((worker) => worker.userId === workerId)
    : undefined;

  const { cancelledInLastMonth = 0 } = currentWorkerDetails?.shifts ?? {};

  return (
    <div className="custom-header">
      <div className="custom-header-title">
        {backAction && (
          <ArrowLeftOutlined
            className="close-channel"
            onClick={() => history.push(getLocation("facilityChat"))}
          />
        )}
        <Stack direction="column" spacing={0.5}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link
              to={getLocation("workplaceWorkerDetails", {
                pathParams: { workerId },
              })}
              onClick={() => handleWorkerNameClicked(false)}
              className="custom-header-worker"
            >
              {getChannelTitle(currentChannel)}
            </Link>
            {isStreaksPreRewardsEnabled && (
              <div>
                {currentStreak && <StreakIndicator streak={currentStreak.attributes} />}
                {isFavoriteFlagEnabled && workerIsFavorite && !isDefined(exclusion) && (
                  <FavoriteIndicator
                    cancelled={isDefined(cancelledInLastMonth) && cancelledInLastMonth > 0}
                  />
                )}
              </div>
            )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <Tooltip
              title={PENDING_ACCOUNT_TOOLTIP_MESSAGE}
              disableHoverListener={facilityStatus !== FacilityStatusObj.PENDING}
              arrow
            >
              <span>
                <Button
                  onClick={() => {
                    editShiftInviteModalState.openModal();
                    logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
                      action: "CHAT_MODAL_OPEN",
                      workplaceId,
                      workplaceUserId,
                      workerType: worker?.qualification,
                      ...shiftInfo,
                    });
                  }}
                  variant="contained"
                  disabled={facilityStatus === FacilityStatusObj.PENDING}
                >
                  Invite Professional
                </Button>
              </span>
            </Tooltip>
            <Button onClick={() => handleWorkerNameClicked(true)} variant="outlined">
              View profile
            </Button>
          </Stack>
        </Stack>
      </div>
      {isWorkerSuccess && isDefined(shiftInfo) && (
        <ShiftInviteDialog
          workerType={worker.qualification}
          modalState={editShiftInviteModalState}
          shiftInfo={shiftInfo}
          initialActiveState={1}
          initialSelectedWorker={{
            workerId: worker.userId,
            phoneNumber: worker.phone,
            fullName: worker.name,
          }}
        />
      )}
    </div>
  );
}

export function ChatPage() {
  const { userId, user, admin, profile } = useSelector<SessionType, Session>(
    (state) => state.session
  );

  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const history = useHistory();
  const { agentId, facilityId } = useParams<{
    agentId: string;
    facilityId: string;
  }>();
  const { channels } = useSelector<SessionType, SendbirdChat>((state) => state.chat);
  const currentChannelUrl = facilityId ? facilityId + "_" + agentId : "";
  const currentChannel = channels.find((channel) => channel.url === currentChannelUrl);
  const dispatch = useDispatch();
  const activeChatWindowDays = useActiveChatWindowDays();

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk) {
        await fetchChannels(sdk, dispatch);
      }
    }
    fetchAndDispatchChannels();
  }, [dispatch, sdk]);

  useEffect(() => {
    if (!admin && facilityId && agentId && user?._id) {
      logEvent(HCF_USER_EVENTS.CHAT_OPENED, {
        location: "chatChannel",
        workerId: agentId,
        workplaceId: facilityId,
        workplaceUserId: user._id,
      });
    }
  }, [facilityId, agentId, user?._id, admin]);

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", agentId, facilityId],
    () =>
      fetchExclusion({
        facilityId,
        agentId,
      }),
    {
      enabled: !!(userId && facilityId && agentId),
    }
  );

  const channelClick = (newChannelUrl: string) => {
    const [facilityId, agentId] = newChannelUrl.split("_");
    history.push(
      getLocation("facilityChatWithAgent", {
        pathParams: { facilityId, agentId },
      })
    );
  };

  return (
    <div className={"chat-container chat-style"}>
      <div className={"chat " + (currentChannelUrl ? "selected-chat" : "")}>
        <div className="channels-container">
          <div className="channels-header">Channels</div>
          {channels.map((channel, index, array) => {
            const classes = ["channel"];

            if (channel.url === currentChannelUrl) {
              classes.push("selected");
            }

            if (index > 0) {
              classes.push("border");
            } // Not First
            if (index === array.length - 1) {
              classes.push("shadow");
            } // Last

            return (
              <div
                tabIndex={0}
                role="row"
                key={channel.url}
                className={classes.join(" ")}
                onClick={() => channelClick(channel.url)}
                onKeyUp={() => channelClick(channel.url)}
              >
                <div className="channel-name">
                  <b>{getChannelTitle(channel)}</b>
                </div>
                <div className="channel-last">{getLastMessageDate(channel)}</div>
                <div className="channel-message">
                  {getLastMessageInfo(channel, sdk?.currentUser?.userId ?? "")}
                </div>
                {channel.unreadMessageCount > 0 ? (
                  <div className="channel-unread">{channel.unreadMessageCount}</div>
                ) : (
                  <div></div>
                )}
                <div className="channel-upcoming-shift">
                  {getChannelShiftTime(channel, activeChatWindowDays)}
                </div>
              </div>
            );
          })}
        </div>

        <Channel
          renderMessage={({ message }) => renderSendbirdChannelMessage({ message, user, userId })}
          onBeforeSendFileMessage={
            sdk && user
              ? (file, quoteMessage) => handleBeforeSendFileMessage(user, file, quoteMessage)
              : undefined
          }
          onBeforeSendUserMessage={
            sdk && user
              ? (text, quoteMessage) => handleBeforeSendUserMessage(user, text, quoteMessage)
              : undefined
          }
          renderMessageInput={getMessageInputDefinition({
            isLoadingExclusion,
            sdk,
            actionBy: exclusions?.[0]?.actionBy,
          })}
          channelUrl={currentChannelUrl}
          renderChannelHeader={() => (
            <ChatHeader
              workerId={agentId}
              workplaceId={facilityId}
              workplaceUserId={user?._id ?? ""}
              currentChannel={currentChannel}
              backAction={true}
              facilityStatus={profile.status}
            />
          )}
        />
      </div>
    </div>
  );
}

export function ChatModal(props: ChatModalProps) {
  const { channelUrl, closeModal } = props;
  const { channels } = useSelector<SessionType, SendbirdChat>((state) => state.chat);
  const { userId, user, profile } = useSelector<SessionType, Session>((state) => state.session);
  const currentChannel = channels.find((channel) => channel.url === channelUrl);
  const [workplaceId, workerId] = (channelUrl ?? "").split("_"); // channel's url is the concatenation of workplaceId_workerId
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;

  const { isLoading: isLoadingExclusion, data: exclusions } = useQuery(
    ["chat", "exclusion", workerId, workplaceId],
    () =>
      fetchExclusion({
        facilityId: workplaceId,
        agentId: workerId,
      }),
    {
      enabled: !!(userId && workerId && workplaceId),
    }
  );

  return (
    <Fragment>
      <Modal
        className="chat-style"
        footer={<Fragment />}
        visible={Boolean(channelUrl)}
        bodyStyle={{ height: 688, maxHeight: "78vh", paddingTop: 50 }}
        onCancel={() => closeModal()}
      >
        <div className="chat-container chat-style">
          <Channel
            renderMessage={({ message }) => renderSendbirdChannelMessage({ message, user, userId })}
            renderMessageInput={getMessageInputDefinition({
              isLoadingExclusion,
              sdk,
              actionBy: exclusions?.[0]?.actionBy,
            })}
            onBeforeSendFileMessage={
              sdk && user
                ? (file, quoteMessage) => handleBeforeSendFileMessage(user, file, quoteMessage)
                : undefined
            }
            onBeforeSendUserMessage={
              sdk && user
                ? (text, quoteMessage) => handleBeforeSendUserMessage(user, text, quoteMessage)
                : undefined
            }
            channelUrl={channelUrl}
            renderChannelHeader={() => (
              <ChatHeader
                workerId={workerId}
                workplaceId={userId}
                workplaceUserId={user?._id ?? ""}
                currentChannel={currentChannel}
                backAction={false}
                facilityStatus={profile.status}
              />
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
}

export function ChatProvider(props: ChatProviderProps) {
  const { children } = props;
  const userId = useSelector<SessionType, string | undefined>((store) => store.session.userId);
  const session = useSelector<SessionType, Session>((store) => store.session);
  const sendBirdAccessToken = useSelector<SessionType, string | undefined>(
    (store) => store.session.sendBirdAccessToken
  );
  let sendbirdAppId = appId;

  if (
    !session.profile ||
    session.type === "ADMIN" ||
    session.admin ||
    session.user?.email.includes("@playwright-hcf.com")
  ) {
    sendbirdAppId = "";
  }

  return (
    <SendbirdProvider
      appId={sendbirdAppId ?? ""}
      userId={userId ?? ""}
      nickname={session.profile?.name}
      accessToken={sendBirdAccessToken}
    >
      <ChatProviderWithStore>{children}</ChatProviderWithStore>
    </SendbirdProvider>
  );
}

function ChatProviderWithStore(props: ChatProviderProps): ReactElement {
  const { children } = props;
  const dispatch = useDispatch();
  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdState?.stores?.sdkStore?.sdk;
  const isSendbirdConcurrentConnectionOptimisationEnabled = useCbhFlag(
    CbhFeatureFlag.SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION,
    {
      defaultValue: false,
    }
  );

  useEffect(() => {
    if (!sdk?.groupChannel) {
      return;
    }

    const uuid = uuidv4();

    const channelHandler = new GroupChannelHandler({
      onChannelChanged: (channel) => {
        updateChannel(dispatch, channel);
      },
      onMessageReceived: (_channel: BaseChannel, message) => {
        if (message.isAdminMessage()) {
          fetchAndSetUpcomingShifts(dispatch);
        }
      },
    });

    channelHandler.onUserReceivedInvitation = (channel) => {
      updateChannel(dispatch, channel);
      fetchAndSetUpcomingShifts(dispatch);
    };

    sdk.groupChannel.addGroupChannelHandler(uuid, channelHandler);

    return () => sdk?.groupChannel.removeGroupChannelHandler?.(uuid);
  }, [dispatch, sdk]);

  useEffect(() => {
    async function fetchAndDispatchChannels() {
      if (sdk) {
        await fetchChannels(sdk, dispatch);
      }
    }
    fetchAndDispatchChannels();
  }, [dispatch, sdk]);

  useEffect(() => {
    // Exit early and avoid adding listeners if the feature flag is turned off
    if (!isSendbirdConcurrentConnectionOptimisationEnabled) {
      return () => null;
    }

    const handleTabChange = async () => {
      if (document.visibilityState === "visible") {
        sdk?.setForegroundState?.();
        await fetchChannels(sdk, dispatch);
      } else {
        sdk?.setBackgroundState?.();
      }
    };

    const handleWindowChange = async () => {
      if (!document.hasFocus() && sdk?.connectionState === "OPEN") {
        sdk?.setBackgroundState?.();
      }

      if (document.hasFocus() && sdk?.connectionState === "CLOSED") {
        sdk?.setForegroundState?.();
        await fetchChannels(sdk, dispatch);
      }
    };

    // This is fired when user switches tabs
    // Internally, we check if the tab is focused, then reconnect, otherwise we disconnect
    document.addEventListener("visibilitychange", handleTabChange);

    // Moving away from current window and coming back to it is tracked via blur and focus events
    // Internally, we check if the tab is focused as the window gets focus back and reconnect, otherwise we disconnect
    window.addEventListener("blur", handleWindowChange);
    window.addEventListener("focus", handleWindowChange);

    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
      window.removeEventListener("blur", handleWindowChange);
      window.removeEventListener("focus", handleWindowChange);
    };
  }, [dispatch, isSendbirdConcurrentConnectionOptimisationEnabled, sdk]);

  return children;
}

function handleBeforeSendUserMessage(
  user: {
    access: string[];
    _id: string;
    email: string;
    name: string;
    permissions?: string[] | undefined;
  },
  text: string,
  quoteMessage?: SendableMessageType
): UserMessageCreateParams {
  const message = typeof text === "string" ? text.trim() : text;
  const params: UserMessageCreateParams = {
    message,
    data: JSON.stringify({
      workplaceUserId: user._id,
      workplaceUserName: user.name,
    }),
  };

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}

function handleBeforeSendFileMessage(
  user: {
    access: string[];
    _id: string;
    email: string;
    permissions?: string[] | undefined;
    name: string;
  },
  file: File,
  quoteMessage?: SendableMessageType
): FileMessageCreateParams {
  const params: FileMessageCreateParams = {
    file,
    data: JSON.stringify({
      workplaceUserId: user._id,
      workplaceUserName: user.name,
    }),
  };

  if (quoteMessage) {
    params.isReplyToChannel = true;
    params.parentMessageId = quoteMessage.messageId;
  }

  return params;
}
