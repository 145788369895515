import * as WorkerJSNew from "./workers.new";

const workers = WorkerJSNew;

const {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
  getWorkerStageLabel,
} = workers;

export {
  workerTypes,
  workerTypeDefaults,
  workerTypesRates,
  workerSpecialities,
  newWorkerTypes,
  allWorkerTypes,
  workerTypeObj,
  WorkerTypes,
  /**
   * There are no references to FacilityWorkerType. Should we delete?
   */
  FacilityWorkerType,
  WORKER_STAGES_ENUM,
  WORKER_STAGES_REASONS,
  WORKER_STAGES_REASONS_DATA,
  hcpNamAssignmentCases,
  /**
   * There are no references to hcpNamAssignmentCaseList. Should we delete?
   */
  hcpNamAssignmentCaseList,
  workerTypesOptions,
  medicalLabWorkerOptions,
  surgicalWorkerTypesOptions,
  /**
   * There are no references to medicalLabWorkerTypes. Should we delete?
   */
  medicalLabWorkerTypes,
  HHCAndHospiceWorkerTypeOptions,
  HospitalWorkerOptions,
  getWorkerShiftTypeLabel,
  psychHospitalWorkerTypesOptions,
  longTermCareWorkerTypesOptions,
  getWorkerStageLabel,
};
