import { z } from "zod";

const qualificationAttributesSchema = z.object({
  name: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const qualificationSchema = z.object({
  type: z.literal("qualification"),
  id: z.string(),
  attributes: qualificationAttributesSchema,
});

export const qualificationsResponseSchema = z.object({
  data: z.array(qualificationSchema),
});

export const qualificationResponseSchema = z.object({
  data: qualificationSchema,
});

const qualificationCreateAttributesSchema = qualificationAttributesSchema.omit({
  createdAt: true,
  updatedAt: true,
});

export type QualificationCreateParams = z.infer<typeof qualificationCreateAttributesSchema>;

export const qualificationCreateRequestSchema = z.object({
  data: z.object({
    type: z.literal("qualification"),
    attributes: qualificationCreateAttributesSchema,
  }),
});

const qualificationEditParams = z
  .object({
    id: z.string(),
  })
  .merge(qualificationCreateAttributesSchema);

export type QualificationEditParams = z.infer<typeof qualificationEditParams>;

export const qualificationEditRequestSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("qualification"),
    attributes: qualificationCreateAttributesSchema,
  }),
});

export type QualificationsResponse = z.infer<typeof qualificationsResponseSchema>;
export type QualificationResponse = z.infer<typeof qualificationResponseSchema>;
export type Qualification = z.infer<typeof qualificationSchema>;
