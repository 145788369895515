import { WorkPlaceTypes } from "./WorkPlaceTypes";
import { WorkPlaceTypesWithWorkerTypes } from "./WorkPlaceTypes";

const defaultWorkPlaceTypes = Object.values(WorkPlaceTypes).map((workPlaceType) => ({
  name: workPlaceType,
  workerTypes: WorkPlaceTypesWithWorkerTypes[workPlaceType].map((workerType) => ({
    name: workerType,
  })),
}));

export { defaultWorkPlaceTypes };
