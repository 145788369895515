import { debounce } from "lodash";
import { useEffect, useState, useCallback } from "react";
import request from "superagent";
import { getAuthHeader } from "../../../utils/superagent";
import { logEvent } from "@src/appV2/lib/analytics";
import { ADMIN_EVENTS } from "../../../constants/firebaseEvents";
import { getErrorMessage } from "../../../utils/errors";
import { AGENTS_LIMIT_FOR_FIRST_LOAD, FACILITIES_LIMIT_FOR_FIRST_LOAD } from "./constants";
import { AccountSearchResults } from "./types";
import { showErrorToast } from "@src/appV2/lib/Notifications";

export const useAccountSwitcherHook = ({ pseudo, closeMenu }) => {
  const [agentDetails, setAgentDetails] = useState<AccountSearchResults>({
    list: [],
    count: 0,
    loading: true,
  });
  const [facilityDetails, setFacilityDetails] = useState<AccountSearchResults>({
    list: [],
    count: 0,
    loading: true,
  });
  const [searchName, setSearchName] = useState();

  const [accountsListVisible, setAccountsListVisible] = useState(false);

  const fetchAgentList = useCallback(
    async (append = 0, limit = AGENTS_LIMIT_FOR_FIRST_LOAD) => {
      setAgentDetails((details) => ({ ...details, loading: true }));

      try {
        const { body } = await request
          .get(`${global.api}/user/agentSearch`)
          .set(await getAuthHeader())
          .query({
            skip: append,
            limit,
            searchInput: searchName,
          });

        if (append) {
          setAgentDetails((details) => ({
            list: [...details.list, ...body.list],
            count: details?.count ?? 0,
            loading: false,
          }));
        } else {
          setAgentDetails(() => ({ ...body, loading: false }));
        }
      } catch (err) {
        showErrorToast(getErrorMessage(err));
        setAgentDetails((details) => ({ ...details, loading: false }));
      }
    },
    [searchName]
  );

  const fetchFacilityList = useCallback(
    async (append = 0, limit = FACILITIES_LIMIT_FOR_FIRST_LOAD) => {
      setFacilityDetails((details) => ({ ...details, loading: true }));

      try {
        const { body } = await request
          .get(`${global.api}/user/facilitySearch`)
          .set(await getAuthHeader())
          .query({ skip: append, limit, searchInput: searchName });

        if (append) {
          setFacilityDetails((details) => ({
            list: [...details.list, ...body.list],
            count: details?.count ?? 0,
            loading: false,
          }));
        } else {
          setFacilityDetails(() => ({ ...body, loading: false }));
        }
      } catch (err) {
        showErrorToast(getErrorMessage(err));
        setFacilityDetails((details) => ({ ...details, loading: false }));
      }
    },
    [searchName]
  );

  useEffect(() => {
    fetchAgentList(0, AGENTS_LIMIT_FOR_FIRST_LOAD);
    fetchFacilityList(0, FACILITIES_LIMIT_FOR_FIRST_LOAD);
  }, [fetchAgentList, fetchFacilityList, searchName]);

  const handleOnDropdownVisible = (open) => {
    setAccountsListVisible(open);
    if (!open) {
      return;
    }
    logEvent(ADMIN_EVENTS.OPEN_SEARCH);
  };

  const searchAccounts = debounce((name) => {
    setSearchName(name);
    logEvent(ADMIN_EVENTS.FILTER_SEARCH, { user_input: name });
  }, 800);

  const handleChange = (value, type) => {
    pseudo({ userId: value });
    if (closeMenu) {
      closeMenu();
    }
    if (type === "AGENT") {
      logEvent(ADMIN_EVENTS.SELECT_HCP, {
        id: value,
      });
    } else if (type === "FACILITY") {
      logEvent(ADMIN_EVENTS.SELECT_HCF, {
        id: value,
      });
    }
    setAccountsListVisible(false);
  };
  return {
    handleChange,
    searchAccounts,
    handleOnDropdownVisible,
    accountsListVisible,
    facilityDetails,
    agentDetails,
    fetchAgentList,
    fetchFacilityList,
    setAccountsListVisible,
  };
};
