import { Mutex } from "async-mutex";
import { logError } from "src/appV2/lib/analytics";
import { defaultWorkerTypes } from "./defaultWorkerTypes";
import { defaultWorkPlaceTypes } from "./defaultWorkPlaceTypes";
import request from "superagent";
import { getAuthHeader } from "../utils/superagent";
import { WORKER_RETRY_COUNT, WORKER_TIMEOUT_DURATION } from "./constants";
import { environmentConfig } from "@src/appV2/environment";

/**
 * @deprecated Replace usages with useGetQualifications and useGetWorkplaceTypes hooks
 */
export class WorkerAndWorkPlaceTypesFacade {
  #localStorageName = "WorkerAndWorkPlaceTypes";

  #syncMutex = new Mutex();

  #lastUpdatedAt = Date.now();

  constructor() {
    this.#initialize();
  }

  #initialize() {
    /* @ts-expect-error FIXME this wrong type */
    const existingData = JSON.parse(localStorage.getItem(this.#localStorageName));
    if (!existingData) {
      this.#saveTypesToLocalStorage(defaultWorkerTypes, defaultWorkPlaceTypes);
    }
  }

  #saveTypesToLocalStorage(workerTypes, workPlaceTypes) {
    localStorage.setItem(
      this.#localStorageName,
      JSON.stringify({
        workerTypes,
        workPlaceTypes,
      })
    );
  }

  /**
   * @deprecated Replace usages with useGetQualifications and useGetWorkplaceTypes hooks
   */
  get allTypes() {
    try {
      if (this.#shouldSyncData()) {
        this.#runSynchronousSyncData();
      }

      /* @ts-expect-error FIXME this wrong type */
      const lastSavedData = JSON.parse(localStorage.getItem(this.#localStorageName));
      if (!lastSavedData) {
        return {
          workerTypes: defaultWorkerTypes,
          workPlaceTypes: defaultWorkPlaceTypes,
        };
      }
      return lastSavedData;
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-allTypes - getting data from local storage", { error });
      return {
        workerTypes: defaultWorkerTypes,
        workPlaceTypes: defaultWorkPlaceTypes,
      };
    }
  }

  /**
   * @deprecated Replace usages with useGetQualifications and useGetWorkplaceTypes hooks
   */
  get workerTypes() {
    try {
      return this.allTypes.workerTypes.map((workerType) => workerType.name);
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-workerTypes - computing workerTypes", {
        error,
      });
      return [];
    }
  }

  /**
   * @deprecated Replace usages with useGetQualifications and useGetWorkplaceTypes hooks
   */
  get workPlaceTypes() {
    try {
      return this.allTypes.workPlaceTypes.reduce(
        (acc, workPlaceType) => ({
          ...acc,
          [workPlaceType.name]: workPlaceType.workerTypes.map((workerType) => workerType.name),
        }),
        {}
      );
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-workPlaceTypes - computing workPlaceTypes", { error });
      return {};
    }
  }

  async #runSynchronousSyncData() {
    try {
      if (this.#syncMutex.isLocked()) {
        return;
      }
      await this.#syncMutex.runExclusive(async () => await this.syncData());
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-workPlaceTypes - running runSynchronousSyncData", {
        error,
      });
    }
  }

  public async syncData() {
    try {
      const {
        body: { data },
      } = await request
        .get(`${environmentConfig.REACT_APP_BASE_API_URL}/workerAndWorkPlaceTypes`)
        .set(await getAuthHeader())
        .send()
        .timeout(WORKER_TIMEOUT_DURATION)
        .retry(WORKER_RETRY_COUNT);

      if (!data) {
        logError("Error in WorkerAndWorkPlace-syncData - Empty data from API", {
          error: new Error("Empty data from API"),
        });
      }
      const { workerTypes, workPlaceTypes } = data;
      this.#saveTypesToLocalStorage(workerTypes, workPlaceTypes);
      this.#lastUpdatedAt = Date.now();
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-syncData - syncing data", { error });
    }
  }

  #shouldSyncData() {
    try {
      const TTL = Number(
        environmentConfig.REACT_APP_WORKER_WORK_PLACE_TYPES_SYNC_TTL_SECONDS || 300
      );
      return (Date.now() - this.#lastUpdatedAt) / 1000 >= TTL;
    } catch (error: unknown) {
      logError("Error in WorkerAndWorkPlace-shouldSyncData - checking whether to sync data", {
        error,
      });
      return false;
    }
  }

  /**
   * @deprecated
   * this is a computed value used by the existing infrastructure
   */
  get workerTypeObj() {
    return this.allTypes.workerTypes.reduce(
      (acc, workerType) => ({
        ...acc,
        [workerType.name.toUpperCase().replace(/ /g, "_")]: workerType.name,
      }),
      {}
    );
  }

  /**
   * @deprecated
   * this is a computed value used by the existing infrastructure
   */
  get workerTypeDefaults() {
    return this.allTypes.workerTypes.reduce((acc, type) => ({ ...acc, [type]: 0 }), {});
  }
}
