import { Session } from "src/modules/interface";
import { workerAndWorkPlaceTypesProvider } from "../workers/workerAndWorkPlaceTypesProvider";

const FacilityTypes = {
  LONG_TERM_CARE: "Long Term Care",
  SURGERY_CENTER: "Surgery Center",
  HOSPICE: "Hospice",
  HOME_HEALTHCARE: "Home Healthcare",
  PSYCH_HOSPITAL: "Psych Hospital",
  MEDICAL_LAB: "Medical Lab",
  HOSPITAL: "Hospital",
  DENTAL_CLINIC: "Dental Clinic",
  SCHOOL: "School",
} as const;

/**
 * @deprecated use workerAndWorkPlaceTypesProvider instead
 */
const facilityTypes = Object.keys(workerAndWorkPlaceTypesProvider.workPlaceTypes);

const chartingTypes = ["Electronic", "Paper"];

const FacilityStatusObj = {
  UNSIGNED: "unsigned",
  ONBOARDING: "onboarding",
  ENROLLED: "enrolled",
  PROBATION: "probation",
  SUSPENDED: "suspended",
  TERMINATED: "terminated",
  ARCHIVED: "archived",
  PENDING: "pending",
} as const;

export const HcfSelfOnboardingCaseCreationStage = {
  FORM_SUBMITTED: "FORM_SUBMITTED",
  TOS_UNSIGNED: "TOS_UNSIGNED",
  PENDING_SIGN_IN: "PENDING_SIGN_IN",
  PENDING_POSTING_SHIFTS: "PENDING_POSTING_SHIFTS",
  ONBOARDING_COMPLETE: "ONBOARDING_COMPLETE",
};

const FacilityStatus = Object.values(FacilityStatusObj);

export { facilityTypes, FacilityTypes, chartingTypes, FacilityStatus, FacilityStatusObj };

export function isSignedFacilityProfile(facilityProfile: Session["profile"]): boolean {
  return facilityProfile && facilityProfile.status !== FacilityStatusObj.UNSIGNED;
}
