import { Menu } from "antd";
import {
  AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS,
  FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS,
} from "./constants";
import { AccountSwitcherOptionGroup } from "./AccountSwitcherOptionGroup";
import { Button } from "@mui/material";

export function AccountSwitcherMenu({
  handleChange,
  adminUserId,
  agentDetails,
  fetchAgentList,
  facilityDetails,
  fetchFacilityList,
}) {
  return (
    <Menu style={{ padding: ".75rem 0 0 1rem" }} data-testid="account-switcher-menu">
      <Button
        variant="outlined"
        sx={{ fontWeight: "bold", paddingY: 2, marginBottom: 2 }}
        onClick={() => handleChange(adminUserId, "ADMIN")}
      >
        <b>Switch To Administrator →</b>
      </Button>
      <div className="account-groups-list">
        <div data-testid="account-switcher-agents">
          <h4 className="account-group">Agent</h4>
          <div className="accounts-list">
            <AccountSwitcherOptionGroup
              type="AGENT"
              userDetails={agentDetails}
              callback={fetchAgentList}
              limit={AGENTS_LIMIT_FOR_SUBSEQUENT_LOADS}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div data-testid="account-switcher-facilities">
          <h4 className="account-group">Facility</h4>
          <div className="accounts-list">
            <AccountSwitcherOptionGroup
              type="FACILITY"
              userDetails={facilityDetails}
              callback={fetchFacilityList}
              limit={FACILITIES_LIMIT_FOR_SUBSEQUENT_LOADS}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </Menu>
  );
}
