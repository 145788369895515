import { WorkerTypes } from "./WorkerTypes";
import { defaultWorkPlaceTypes } from "./defaultWorkPlaceTypes";

const defaultWorkerTypes = Object.values(WorkerTypes).map((workerTypeName) => {
  const workPlaceTypesForWorkerType: any[] = [];
  defaultWorkPlaceTypes.forEach((workPlaceType) => {
    if (workPlaceType?.workerTypes?.some((workerType) => workerType?.name === workerTypeName)) {
      workPlaceTypesForWorkerType.push({ name: workPlaceType.name });
    }
  });
  return {
    name: workerTypeName,
    workPlaceTypes: workPlaceTypesForWorkerType,
  };
});

export { defaultWorkerTypes };
