import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useMemo, useReducer } from "react";
import { ResponseError } from "superagent";
import { permissionDeniedInitial, permissionDeniedReducer } from "./permissionDenied";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { enqueueSnackbar } from "notistack";

interface QueryProviderProps {
  children: React.ReactNode;
}

function isPermissionDeniedError(error: unknown): error is ResponseError {
  return (
    error instanceof Error && "status" in error && error["status"] === 403 && "response" in error
  );
}

export function QueryProvider({ children }: QueryProviderProps) {
  const [permissionDenied, dispatchPermissionDenied] = useReducer(
    permissionDeniedReducer,
    permissionDeniedInitial
  );

  const handleApiError = (error: unknown) => {
    if (isPermissionDeniedError(error)) {
      dispatchPermissionDenied({
        type: error.response?.body?.type,
        data: error.response?.body,
        reducer: dispatchPermissionDenied,
      });
    }
  };

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          // do not retry if response status code is 403
          retry: (failureCount, error) => {
            const isPermissionDenied =
              error instanceof Error ? "status" in error && error["status"] === 403 : false;
            return failureCount < 3 && !isPermissionDenied;
          },
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          handleApiError(error);

          const { meta: { logErrorMessage, userErrorMessage } = {} } = query;

          if (logErrorMessage) {
            logError(String(logErrorMessage), { error });
          }

          if (userErrorMessage) {
            enqueueSnackbar({
              variant: "error",
              message: userErrorMessage,
            });
          }
        },
        onSuccess: (_, query) => {
          const { meta: { logSuccessMessage, userSuccessMessage } = {} } = query;
          if (logSuccessMessage) {
            logEvent(String(logSuccessMessage));
          }

          if (userSuccessMessage) {
            enqueueSnackbar({
              variant: "success",
              message: userSuccessMessage,
            });
          }
        },
      }),
      mutationCache: new MutationCache({
        onError(error, _variables, _context, mutation) {
          handleApiError(error);

          const { meta: { logErrorMessage, userErrorMessage } = {} } = mutation;
          if (logErrorMessage) {
            logError(String(logErrorMessage), { error });
          }

          if (userErrorMessage) {
            enqueueSnackbar({
              variant: "error",
              message: userErrorMessage,
            });
          }
        },
        onSuccess(_data, _variables, _context, mutation) {
          const { meta: { logSuccessMessage, userSuccessMessage } = {} } = mutation;
          if (logSuccessMessage) {
            logEvent(String(logSuccessMessage));
          }

          if (userSuccessMessage) {
            enqueueSnackbar({
              variant: "success",
              message: userSuccessMessage,
            });
          }
        },
      }),
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {permissionDenied.content}
    </QueryClientProvider>
  );
}
