import { z } from "zod";

export enum ShiftInviteStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DELETED = "DELETED",
  PENDING = "PENDING",
}

const shiftInviteDetailsSchema = z.object({
  start: z.date(),
  end: z.date(),
  agentReq: z.string(),
  name: z.string().nullish(),
  tmz: z.string().optional(),
});

export type ShiftInviteDetails = z.infer<typeof shiftInviteDetailsSchema>;

export const shiftInviteAttributeSchema = z.object({
  workerId: z.string(),
  workplaceId: z.string(),
  shiftDetails: shiftInviteDetailsSchema,
  postShiftToMarketplace: z.boolean(),
  groupId: z.string().optional(),
});

export const shiftInviteRequestSchema = z.object({
  data: z.object({
    attributes: shiftInviteAttributeSchema,
    type: z.string(),
  }),
});

export interface ShiftInviteRequestParams {
  shiftInviteRequest: z.infer<typeof shiftInviteAttributeSchema>;
}

export const shiftInviteValidationResponseSchema = z.object({
  data: z.object({
    attributes: z.object({
      status: z.string(),
    }),
  }),
});
