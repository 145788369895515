import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  TimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { convertShiftTimeToDateTime } from "src/containers/facilityDashboard/ShiftCalendar/newShift/shiftDate";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { isSameDay, startOfDay } from "date-fns";
import { ShiftName } from "src/interface";
import { DEFAULT_TIMEZONE } from "src/constants/timezone";
import { ShiftInviteQueryParams, useCreateShiftInvite } from "./useCreateShiftInvite";
import { NearbyWorkerSearchResult } from "./Autocomplete/useGetNearbyWorkers";
import { formatDate, formatTime } from "@clipboard-health/date-time";
import { SessionType } from "src/modules/interface";
import {
  type ShiftInviteDialogProps,
  ShiftInviteErrorDialog,
  ShiftInviteOverworkDialog,
} from "./Dialogs";
import { useGetShiftInviteExpirationDuration } from "./useGetShiftInviteExpirationDuration";
import { isRequestErrorResponse } from "src/utils/errors";
import { logError, logEvent } from "src/appV2/lib/analytics";
import { NON_BOOKABLE_CRITERIA } from "./constants";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { ShiftInviteMissingFacilityDocumentsDialog } from "./Dialogs/ShiftInviteMissingFacilityDocumentsDialog";
import { ShiftInviteMissingCoreDocumentsDialog } from "./Dialogs/ShiftInviteMissingCoreDocumentsDialog";
import { RequirementMappingLevel } from "@src/constants/documents";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { showSuccessToast } from "@src/appV2/lib/Notifications";

export interface ShiftInviteDetailsProps {
  shiftInviteDialogProps: ShiftInviteDialogProps;
  setActiveStep: (step: number) => void;
  selectedWorker: NearbyWorkerSearchResult | undefined;
  setSelectedWorker: (worker: NearbyWorkerSearchResult | undefined) => void;
}

export function ShiftInviteDetails(props: ShiftInviteDetailsProps) {
  const { selectedWorker, setSelectedWorker, setActiveStep, shiftInviteDialogProps } = props;
  const { shiftInfo, workerType, modalState } = shiftInviteDialogProps;
  const { profile, user, admin } = useSelector((state: SessionType) => state.session);
  const facilityTimeZone = profile?.tmz ?? DEFAULT_TIMEZONE;
  const workplaceId = profile?.userId;
  const [shiftType, setShiftType] = useState(shiftInfo.shiftType);

  const [selectedDate, setSelectedDate] = useState<Date>(
    utcToZonedTime(shiftInfo.start, facilityTimeZone)
  );
  const [shiftStartTime, setShiftStartTime] = useState<Date>(
    utcToZonedTime(shiftInfo.start, facilityTimeZone)
  );
  const [shiftEndTime, setShiftEndTime] = useState<Date>(
    utcToZonedTime(shiftInfo.end, facilityTimeZone)
  );

  const [isChecked, setIsChecked] = useState(true);
  const shiftInviteErrorModalState = useModalState();
  const shiftInviteOverworkModalState = useModalState();
  const shiftInviteMissingFacilityDocumentsModalState = useModalState();
  const shiftInviteMissingCoreDocumentsModalState = useModalState();

  const { mutateAsync: createShiftInvite, isLoading: isSaving } = useCreateShiftInvite();

  const expirationDuration = useGetShiftInviteExpirationDuration(
    zonedTimeToUtc(shiftStartTime, facilityTimeZone)
  );

  const minDate = startOfDay(utcToZonedTime(new Date(), facilityTimeZone));

  const segmentProperties = {
    workplaceId,
    workerType,
    ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
    ...(selectedWorker ?? {}),
    ...shiftInfo,
  };

  const [startTimeError, setStartTimeError] = useState<TimeValidationError>();
  const [endTimeError, setEndTimeError] = useState<TimeValidationError>();
  const [startDateError, setStartDateError] = useState<DateValidationError>();
  const [workerMissingDocuments, setWorkerMissingDocuments] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { shiftTimes } = useShiftInviteContext();
  const [skipFacilityRequiredDocumentCheck, setSkipFacilityRequiredDocumentCheck] = useState(false);

  async function sendInvite(queryParams: ShiftInviteQueryParams = {}) {
    try {
      const shiftInviteRequest = {
        workerId: selectedWorker?.workerId ?? "",
        workplaceId,
        shiftDetails: {
          start: zonedTimeToUtc(shiftStartTime, facilityTimeZone),
          end: zonedTimeToUtc(shiftEndTime, facilityTimeZone),
          agentReq: workerType,
          name: shiftType,
          tmz: facilityTimeZone,
        },
        postShiftToMarketplace: isChecked,
      };

      await createShiftInvite({
        shiftInviteRequest,
        queryParams,
      });
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "SENT",
        ...segmentProperties,
      });
      modalState.closeModal();
      showSuccessToast(
        `Your invite to ${selectedWorker?.fullName}, for ${formatDate(
          shiftStartTime
        )} from ${formatTime(shiftStartTime)} to ${formatTime(
          shiftEndTime
        )} has been sent! You can view and manage this invite on your calendar.`
      );
    } catch (error: unknown) {
      logError("Error while creating the shift invite", {
        error,
      });

      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "SEND_ERROR",
        ...segmentProperties,
      });

      if (isRequestErrorResponse(error)) {
        const errorData = error?.response?.body.errors?.[0];
        const unmetCriteria = errorData?.reasons;
        const missingDocuments = errorData?.missingDocuments;
        if (
          unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.WORKER_MISSING_REQUIREMENTS) &&
          missingDocuments?.every((missingDoc) => missingDoc.level === RequirementMappingLevel.HCF)
        ) {
          setWorkerMissingDocuments(missingDocuments.map((missingDoc) => missingDoc.name));
          shiftInviteMissingFacilityDocumentsModalState.openModal();
          return;
        }

        if (
          unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.WORKER_MISSING_REQUIREMENTS) &&
          missingDocuments?.length > 0
        ) {
          setWorkerMissingDocuments(
            missingDocuments
              .filter((missingDoc) => missingDoc.level !== RequirementMappingLevel.HCF)
              .map((coreDoc) => coreDoc.name)
          );
          shiftInviteMissingCoreDocumentsModalState.openModal();
          return;
        }

        if (unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.SHIFT_OVERWORKING)) {
          shiftInviteOverworkModalState.openModal();
          return;
        }

        setErrorMessage(errorData?.detail);
      }

      shiftInviteErrorModalState.openModal();
    }
  }

  return (
    <>
      <DatePicker
        label="Date"
        value={selectedDate}
        onChange={(newValue) => {
          if (!isDefined(newValue) || newValue < minDate) {
            return;
          }
          setSelectedDate(newValue);
          if (!isDefined(shiftTimes) || !isDefined(shiftType)) {
            return;
          }
          const shiftTime = convertShiftTimeToDateTime({
            shiftTime: shiftTimes[shiftType],
            startDate: newValue,
          });
          setShiftStartTime(shiftTime.startTime);
          setShiftEndTime(shiftTime.endTime);
        }}
        format="MM/dd/yyyy"
        minDate={minDate}
        onError={(newError) => {
          setStartDateError(newError);
        }}
        slotProps={{
          textField: {
            helperText: startDateError ? "Shift Date can't be in the past." : "",
          },
        }}
      />
      <Stack>
        <Text>Shift Type</Text>
        <ToggleButtonGroup
          color="info"
          value={shiftType}
          exclusive
          onChange={(_event, newShiftType) => {
            if (!isDefined(newShiftType)) {
              return;
            }

            setShiftType(newShiftType);
            if (!isDefined(shiftTimes)) {
              return;
            }
            const shiftTime = convertShiftTimeToDateTime({
              shiftTime: shiftTimes[newShiftType],
              startDate: selectedDate,
            });

            setShiftStartTime(shiftTime.startTime);
            setShiftEndTime(shiftTime.endTime);
          }}
          size="large"
          fullWidth={true}
        >
          {Object.values(ShiftName).map((type) => (
            <ToggleButton key={type} value={type}>
              {type.toUpperCase()}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <Stack spacing={1} direction="row">
        <TimePicker
          label="Start time"
          value={shiftStartTime}
          onChange={(newValue) => {
            if (isDefined(newValue)) {
              setShiftStartTime(newValue);
            }
          }}
          disableIgnoringDatePartForTimeValidation={true}
          minTime={utcToZonedTime(new Date(), facilityTimeZone)}
          onError={(newError) => {
            setStartTimeError(newError);
          }}
          slotProps={{
            textField: {
              helperText: startTimeError ? "Shift Start time can't be in the past." : "",
            },
          }}
        />
        <TimePicker
          label={isSameDay(shiftStartTime, shiftEndTime) ? "End time" : "End time (next day)"}
          value={shiftEndTime}
          onChange={(newValue) => {
            if (isDefined(newValue)) {
              setShiftEndTime(newValue);
            }
          }}
          disableIgnoringDatePartForTimeValidation={true}
          minTime={utcToZonedTime(new Date(), facilityTimeZone)}
          onError={(newError) => {
            setEndTimeError(newError);
          }}
          slotProps={{
            textField: {
              helperText:
                endTimeError === "minTime"
                  ? "Shift End time can't be in the past."
                  : isDefined(endTimeError)
                  ? "Shift End time should be greater than Shift Start time."
                  : "",
            },
          }}
          shouldDisableTime={(time) => {
            if (time < shiftStartTime) {
              return true;
            }
            return false;
          }}
        />
      </Stack>
      <FormControlLabel
        control={<Checkbox checked={isChecked} onChange={() => setIsChecked((value) => !value)} />}
        label={
          <Text variant="body2" textAlign="left" sx={{ fontStyle: "italic" }}>
            If the professional declines this invite, or it expires, post this shift to my favorite
            workers, and then to other available professionals. This invitation will automatically
            expire in {expirationDuration}.
          </Text>
        }
      />
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          onClick={() => {
            setActiveStep(0);
            setSelectedWorker(undefined);
            logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
              action: "NOT_SENT",
              ...segmentProperties,
            });
          }}
          variant="outlined"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            await sendInvite();
          }}
          variant="contained"
          disabled={isSaving || isDefined(startTimeError || endTimeError || startDateError)}
        >
          Send Invite
        </Button>
      </Stack>
      <ShiftInviteErrorDialog
        modalState={shiftInviteErrorModalState}
        errorMessage={errorMessage}
      ></ShiftInviteErrorDialog>
      <ShiftInviteOverworkDialog
        modalState={shiftInviteOverworkModalState}
        selectedWorkerName={selectedWorker?.fullName ?? ""}
        sendInviteWithAllowedOverWorking={async () => {
          shiftInviteOverworkModalState.closeModal();
          await sendInvite({ isOverworkingShiftsAllowed: true, skipFacilityRequiredDocumentCheck });
          logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
            action: "AGREE_OVER_WORK",
            isOverworkingShiftsAllowed: true,
            ...segmentProperties,
          });
        }}
      ></ShiftInviteOverworkDialog>
      <ShiftInviteMissingFacilityDocumentsDialog
        modalState={shiftInviteMissingFacilityDocumentsModalState}
        selectedWorkerName={selectedWorker?.fullName ?? ""}
        missingDocuments={workerMissingDocuments}
        sendInviteWithSkipFacilityDocumentCheck={async () => {
          setSkipFacilityRequiredDocumentCheck(true);
          shiftInviteMissingFacilityDocumentsModalState.closeModal();
          await sendInvite({ skipFacilityRequiredDocumentCheck: true });
          logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
            action: "SKIP_FACILITY_DOCUMENT_CHECK",
            skipFacilityRequiredDocumentCheck: true,
            workerMissingDocuments: workerMissingDocuments.join(","),
            ...segmentProperties,
          });
        }}
      ></ShiftInviteMissingFacilityDocumentsDialog>
      <ShiftInviteMissingCoreDocumentsDialog
        modalState={shiftInviteMissingCoreDocumentsModalState}
        selectedWorkerName={selectedWorker?.fullName ?? ""}
        missingDocuments={workerMissingDocuments}
      ></ShiftInviteMissingCoreDocumentsDialog>
    </>
  );
}
