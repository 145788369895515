import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { FiFilter } from "react-icons/fi";
import { VerticalSpacing } from "src/designsystem/VerticalSpacing/VerticalSpacing";
import { useDebouncedValue } from "src/hooks/useDebounceValue";
import { SessionType } from "src/modules/interface";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useSelector } from "react-redux";
import {
  ArrayParam,
  QueryParamConfig,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import {
  FilterButton,
  FilterSection,
  PageTitle,
  SearchField,
  Workers,
  Wrapper,
} from "./WorkersDirectory.styles";
import { ListWorkers } from "./components/ListWorkers";
import { useHighRatingWorkers } from "./useHighRatingWorkers";
import {
  LoadingContainer,
  LoadingIcon,
} from "src/containers/workers/ListWorkers/ListWorkers.styles";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { WorkerFilterModal } from "./components/WorkerFilterModal";
import { Badge } from "antd";
import { Colors } from "src/utils/colors";
import { RestrictedSignedProfile } from "src/components/RestrictedSignedProfile/RestrictedSignedProfile";
import { useWorkerBlockBookingFeatureFlag } from "src/containers/workplaceBlockShifts/useWorkerBlockBookingFeatureFlag";
import { useGetWorkplaceTypes } from "@src/appV2/Admin/WorkplaceTypes/api/useGetWorkplaceTypes";
import { getQualificationNamesForWorkplaceType } from "@src/appV2/Admin/WorkplaceTypes/utils/getQualificationNamesForWorkplaceType";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export const WORKER_DIRECTORY = {
  WORKER_SEARCHED: "worker searched",
  LOAD_MORE_WORKERS: "load more workers",
  FILTER_OPENED: "filter modal opened",
  FILTER_APPLIED: "filtered applied",
  FILTER_CLEARED: "filter cleared",
  FILTER_CLOSED: "filter closed",
} as const;

type WorkerDirectoryActionKeys = keyof typeof WORKER_DIRECTORY;
type WorkerDirectoryActionValues = typeof WORKER_DIRECTORY[WorkerDirectoryActionKeys];

const NonNullArrayParam = withDefault(ArrayParam, undefined) as QueryParamConfig<
  string[] | undefined
>;

export function WorkersDirectoryPage(): JSX.Element {
  const [searchQuery, setSearchQuery] = useQueryParam("q", StringParam);
  const [qualificationFilter, setQualificationFilter] = useQueryParam(
    "workerType",
    NonNullArrayParam
  );
  const [debouncedSearchQuery] = useDebouncedValue(searchQuery);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const {
    userId,
    profile: { onboardingSteps, userId: workplaceId, type, ...profile },
    user,
    admin,
  } = useSelector((state: SessionType) => state.session);
  const {
    data: workplaceTypes,
    isSuccess: workplaceTypesIsSuccess,
    isLoading: workplaceTypesIsLoading,
  } = useGetWorkplaceTypes();
  const qualifications = workplaceTypesIsSuccess
    ? getQualificationNamesForWorkplaceType(workplaceTypes, type)
    : [];

  const workplaceOnboardingSteps = useCbhFlag(
    CbhFeatureFlag.WORKPLACE_ONBOARDING_STEPS_AB_TESTING,
    {
      defaultValue: {
        orders: ["favorites", "shifts"],
      },
    }
  )?.orders;

  const postShiftOptionSteps = useCbhFlag(CbhFeatureFlag.POST_SHIFT_OPTIONS_AB_TESTING, {
    defaultValue: {
      orders: ["block-shifts", "per-diem"],
    },
  })?.orders;

  const isWorkerBlockBookingEnabled = useWorkerBlockBookingFeatureFlag();
  const {
    isLoading: workerDirectoryLoading,
    data: highRatingWorkers,
    isError: highRatingWorkersError,
    fetchNextPage: fetchHighRatingWorkersNextPage,
    hasNextPage,
    invalidateCache,
  } = useHighRatingWorkers(
    userId,
    {
      searchTerm: debouncedSearchQuery ?? undefined,
      workerType: qualificationFilter?.length ? qualificationFilter : undefined,
    },
    admin,
    {
      workplaceOnboardingSteps,
      postShiftOptionSteps,
      workplaceUserId: user?._id,
      onboardingState: onboardingSteps,
    }
  );

  const logWorkerDirectoryItemEvents = useCallback(
    (
      action: WorkerDirectoryActionValues,
      searchTerm?: string | null,
      qualificationsToFilter?: string[] | null
    ) => {
      if (admin) {
        return;
      }

      const commonAttributes = {
        workplaceOnboardingSteps,
        postShiftOptionSteps,
        workplaceId: userId,
        workplaceUserId: user?._id,
        onboardingState: onboardingSteps,
      };

      logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY, {
        ...commonAttributes,
        action,
        searchTerm,
        workerTypesToFilter: qualificationsToFilter,
      });
    },
    [admin, postShiftOptionSteps, onboardingSteps, user?._id, userId, workplaceOnboardingSteps]
  );

  useEffect(() => {
    function logPageView() {
      if (!workplaceId || admin) {
        return;
      }
      logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY_PAGE_VIEWED, {
        workplaceOnboardingSteps,
        postShiftOptionSteps,
        workplaceId,
        workplaceUserId: user?._id,
        onboardingState: onboardingSteps,
      });
    }
    logPageView();
  }, [
    admin,
    postShiftOptionSteps,
    onboardingSteps,
    workplaceId,
    user?._id,
    workplaceOnboardingSteps,
  ]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      // log search event only when debouncedSearchQuery is not empty or it changes
      logWorkerDirectoryItemEvents(
        WORKER_DIRECTORY.WORKER_SEARCHED,
        debouncedSearchQuery,
        qualificationFilter
      );
    }
    // logWorkerDirectoryItemEvents changes with filter values too so this was being called when the filter updates, hence had to remove the dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleSearchChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterToggle = (value: boolean) => {
    logWorkerDirectoryItemEvents(
      value ? WORKER_DIRECTORY.FILTER_OPENED : WORKER_DIRECTORY.FILTER_CLOSED,
      debouncedSearchQuery,
      qualificationFilter
    );
    setShowFilterModal(value);
  };

  const handleWorkerTypeFilter = (qualificationsToFilter: string[]) => {
    setQualificationFilter([...qualificationsToFilter]);
    setShowFilterModal(false);
    logWorkerDirectoryItemEvents(
      qualificationsToFilter.length
        ? WORKER_DIRECTORY.FILTER_APPLIED
        : WORKER_DIRECTORY.FILTER_CLEARED,
      debouncedSearchQuery,
      qualificationsToFilter
    );
    invalidateCache();
  };

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: workerDirectoryLoading,
    hasNextPage: !!hasNextPage,
    disabled: highRatingWorkersError,
    onLoadMore: fetchHighRatingWorkersNextPage,
  });

  const renderLoadMore = () => {
    return (
      <>
        <VerticalSpacing size="lg" />
        <LoadingContainer ref={infiniteScrollRef}>
          <LoadingIcon indicator={<LoadingOutlined spin />} />
        </LoadingContainer>
      </>
    );
  };

  return (
    <Wrapper>
      <PageTitle>Browse Workers</PageTitle>
      <VerticalSpacing />
      <div>
        {isWorkerBlockBookingEnabled
          ? "This is the full list of professionals in your area. Mark your preferred professionals with a heart so they get early access to book your shifts. Offer blocks of shifts to as many professionals as you'd like."
          : "This is the full list of professionals in your area. Mark your preferred professionals with a heart so they get early access to book your shifts."}
      </div>
      <VerticalSpacing size="lg" />
      <FilterSection>
        <RestrictedSignedProfile action="search workers" page="Worker Directory">
          <SearchField
            data-testid="search-workers"
            placeholder="Search by professional name..."
            size="large"
            value={searchQuery ?? ""}
            onChange={handleSearchChanged}
            allowClear
            prefix={<SearchOutlined />}
          />
        </RestrictedSignedProfile>
        <RestrictedSignedProfile action="filter workers" page="Worker Directory">
          <FilterButton
            variant="ghost"
            size="md"
            onClick={() => handleFilterToggle(true)}
            disabled={
              workplaceTypesIsLoading || qualifications.length === 0 || workerDirectoryLoading
            }
          >
            {qualificationFilter?.length ? (
              <Badge count={qualificationFilter.length} style={{ backgroundColor: Colors.grey }} />
            ) : (
              <FiFilter />
            )}
            Filter
          </FilterButton>
        </RestrictedSignedProfile>
      </FilterSection>
      <VerticalSpacing size="lg" />
      <Workers>
        <ListWorkers
          workers={highRatingWorkers}
          workplaceId={userId}
          workplaceUserId={user?._id}
          onboardingState={onboardingSteps}
          postShiftOptionSteps={postShiftOptionSteps}
          workplaceOnboardingSteps={workplaceOnboardingSteps}
          whoCalledMe="DirectoryWorkerPage"
          hasPermissionToAccessBlockBookingFeature={isWorkerBlockBookingEnabled}
        />
      </Workers>
      {highRatingWorkers.length === 0 && !workerDirectoryLoading ? (
        searchQuery ? (
          <>We couldn't find any workers matching "{searchQuery}" in your area.</>
        ) : (
          <>There are currently no available professionals in your area.</>
        )
      ) : undefined}
      {(workerDirectoryLoading || hasNextPage) && renderLoadMore()}

      {showFilterModal && (
        <WorkerFilterModal
          qualifications={qualifications}
          visible={showFilterModal}
          initialQualifications={qualificationFilter ?? []}
          onCancel={() => handleFilterToggle(false)}
          onFilter={handleWorkerTypeFilter}
        />
      )}
    </Wrapper>
  );
}
