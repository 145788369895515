import WorkerType from "src/workers/interfaces/WorkerType";
import WorkPlaceType from "src/workers/interfaces/WorkPlaceType";
import { WorkerAndWorkPlaceTypeActions, WorkerTypesAction, WorkPlaceTypesAction } from ".";

const getWorkerTypeUpdateAction = (workerTypes: WorkerType[]): WorkerTypesAction => {
  return {
    type: WorkerAndWorkPlaceTypeActions.UPDATE_WORKER_TYPES,
    payload: workerTypes,
  };
};

const getWorkPlaceTypeUpdateAction = (workPlaceTypes: WorkPlaceType[]): WorkPlaceTypesAction => {
  return {
    type: WorkerAndWorkPlaceTypeActions.UPDATE_WORK_PLACE_TYPES,
    payload: workPlaceTypes,
  };
};

const getStartedFetchingTypesAction = () => ({
  type: WorkerAndWorkPlaceTypeActions.STARTED_FETCHING_TYPES,
});
const getFinishedFetchingTypesAction = () => ({
  type: WorkerAndWorkPlaceTypeActions.FINISHED_FETCHING_TYPES,
});

export {
  getWorkerTypeUpdateAction,
  getWorkPlaceTypeUpdateAction,
  getStartedFetchingTypesAction,
  getFinishedFetchingTypesAction,
};
