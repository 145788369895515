import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { differenceInHours, formatDuration } from "date-fns";

const ONE_HOUR_IN_MINUTES = 60;

export interface ShiftInvitesConfig {
  expirationTime: {
    ranges: Array<{ maxLeadTimeInHours: number; bookingTimeInMinutes: number }>;
    defaultBookingTimeInMinutes: number;
  };
  notifications: {
    aboutToExpireTimeInMinutes: number;
  };
}

const ShiftInvitesConfigDefault: ShiftInvitesConfig = {
  expirationTime: {
    ranges: [
      { maxLeadTimeInHours: 2, bookingTimeInMinutes: 20 },
      { maxLeadTimeInHours: 24, bookingTimeInMinutes: 120 },
    ],
    defaultBookingTimeInMinutes: 360,
  },
  notifications: {
    aboutToExpireTimeInMinutes: 10,
  },
};

export function useGetShiftInviteExpirationDuration(shiftStartTimeInUTC: Date): string {
  const shiftInviteConfig = useCbhFlag(CbhFeatureFlag.SHIFT_INVITE_CONFIGURATION, {
    defaultValue: ShiftInvitesConfigDefault,
  });

  const { expirationTime: expirationTimeConfig } = shiftInviteConfig;
  const { ranges, defaultBookingTimeInMinutes } =
    expirationTimeConfig ?? ShiftInvitesConfigDefault.expirationTime;

  const now = new Date();
  const leadTime = differenceInHours(shiftStartTimeInUTC, now);

  const selectedRange = [...ranges]
    .sort((a, b) => a.maxLeadTimeInHours - b.maxLeadTimeInHours)
    .find((range) => leadTime < range.maxLeadTimeInHours);

  const result = selectedRange ? selectedRange.bookingTimeInMinutes : defaultBookingTimeInMinutes;

  const hours = Math.floor(result / ONE_HOUR_IN_MINUTES);
  const minutes = result % ONE_HOUR_IN_MINUTES;
  return formatDuration({ hours, minutes });
}
