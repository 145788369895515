import constate from "constate";
import { useState } from "react";
import { GroupShiftInvite, Severity, ShiftInviteInfo } from "../ShiftInviteReview";
import { useShiftTimes } from "@src/containers/postShifts/hooks/useShiftTimes";
import { SessionType } from "@src/modules/interface";
import { useSelector } from "react-redux";
import { DEFAULT_TIMEZONE } from "@src/constants/timezone";
import { SelectedShiftInfo } from "../ShiftTypeTimeSelection";
import { isDefined } from "@clipboard-health/util-ts";
import { getShiftInfoKey } from "../utils";
import { useValidateShiftInvite } from "@src/appV2/ShiftInvites/api/useValidateShiftInvite";
import { AxiosError } from "axios";
import { NON_BOOKABLE_CRITERIA } from "../constants";
import { RequirementMappingLevel } from "@src/constants/documents";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { logEvent } from "src/appV2/lib/analytics";
import { NearbyWorkerSearchResult } from "../Autocomplete/useGetNearbyWorkers";
import { zonedTimeToUtc } from "date-fns-tz";
import { isValid } from "date-fns";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

interface UseShiftInviteParams {
  agentReq: string;
}

type MultipleShiftInfoKey = string;

interface ValidateShiftInfoParams {
  shiftInviteInfo: ShiftInviteInfo;
  selectedWorker: NearbyWorkerSearchResult | undefined;
}

interface ValidateAndUpdateShiftInviteParams {
  selectedShiftInfo: SelectedShiftInfo;
  selectedDate: Date | undefined;
  selectedWorker: NearbyWorkerSearchResult | undefined;
}

interface ShiftInviteValidationDetails {
  message: string;
  level: Severity;
  workerMissingFacilityDocuments?: string;
  isOverworkingShift?: boolean;
}

function useShiftInvite(params: UseShiftInviteParams) {
  const { agentReq } = params;
  const { profile } = useSelector((state: SessionType) => state.session);
  const workplaceId = profile?.userId;
  const facilityTimeZone = profile?.tmz ?? DEFAULT_TIMEZONE;
  const { data: shiftTimes } = useShiftTimes(workplaceId);

  const isMultiShiftInvitesEnabled = useCbhFlag(CbhFeatureFlag.ROLLOUT_MULTI_SHIFT_INVITES, {
    defaultValue: false,
  });
  const [groupInviteSharedData, setGroupInviteSharedData] = useState<GroupShiftInvite>({
    workplaceId,
    agentReq,
    tmz: facilityTimeZone,
    postShiftToMarketplace: true,
  });
  const [multipleShiftInfo, setMultipleShiftInfo] = useState<
    Map<MultipleShiftInfoKey, ShiftInviteInfo>
  >(new Map<MultipleShiftInfoKey, ShiftInviteInfo>());
  const [multipleShiftValidationDetails, setMultipleShiftValidationDetails] = useState<
    Map<string, ShiftInviteValidationDetails>
  >(new Map<string, ShiftInviteValidationDetails>());
  const { mutateAsync: validateShiftInviteAsync, isLoading: isValidatingShiftInfo } =
    useValidateShiftInvite();
  const { user, admin } = useSelector((state: SessionType) => state.session);

  async function validateShiftInfo(validateShiftInfoParams: ValidateShiftInfoParams) {
    const { shiftInviteInfo, selectedWorker } = validateShiftInfoParams;
    try {
      await validateShiftInviteAsync({
        shiftInviteRequest: {
          workerId: selectedWorker?.workerId ?? "",
          workplaceId,
          shiftDetails: {
            start: zonedTimeToUtc(shiftInviteInfo.start, facilityTimeZone),
            end: zonedTimeToUtc(shiftInviteInfo.end, facilityTimeZone),
            agentReq: groupInviteSharedData.agentReq,
          },
          postShiftToMarketplace: groupInviteSharedData.postShiftToMarketplace,
        },
      });
    } catch (error: unknown) {
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "VALIDATION_ERROR",
        ...{
          workplaceId,
          workerType: agentReq,
          ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
          ...(selectedWorker ?? {}),
        },
      });
      if (error instanceof AxiosError) {
        const errorData = error.response?.data.errors?.[0];
        const unmetCriteria = errorData?.reasons;
        const missingDocuments = errorData?.missingDocuments;
        const errorMessage = errorData?.detail ?? "Invalid Shift Detail";
        if (
          unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.WORKER_MISSING_REQUIREMENTS) &&
          missingDocuments?.every((missingDoc) => missingDoc.level === RequirementMappingLevel.HCF)
        ) {
          const workerMissingFacilityDocuments = missingDocuments.map((doc) => doc.name).join(", ");
          return {
            message: `Worker Missing - ${workerMissingFacilityDocuments}`,
            level: Severity.WARN,
            workerMissingFacilityDocuments,
          };
        }

        const nonHCFLevelMissingDocs = missingDocuments
          ?.filter((missingDoc) => missingDoc.level !== RequirementMappingLevel.HCF)
          .map((coreDoc) => coreDoc.name);

        if (
          unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.WORKER_MISSING_REQUIREMENTS) &&
          nonHCFLevelMissingDocs?.length > 0
        ) {
          return {
            message: `Worker Missing - ${nonHCFLevelMissingDocs.join(", ")}`,
            level: Severity.ERROR,
          };
        }

        if (unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.SHIFT_OVERWORKING)) {
          return {
            message: "May Lead to Overworking",
            level: Severity.WARN,
            isOverworkingShift: true,
          };
        }

        return { message: errorMessage, level: Severity.ERROR };
      }
    }
  }

  const validateAndUpdateShiftInvite = async (
    validateAndUpdateShiftInviteParams: ValidateAndUpdateShiftInviteParams
  ) => {
    const { selectedShiftInfo, selectedDate, selectedWorker } = validateAndUpdateShiftInviteParams;
    const { shiftType, start, end, isSelected } = selectedShiftInfo;
    if (
      !isDefined(start) ||
      !isDefined(end) ||
      !isDefined(selectedDate) ||
      !isValid(start) ||
      !isValid(end) ||
      !isValid(selectedDate)
    ) {
      return;
    }
    const shiftInfoKey = getShiftInfoKey({ date: selectedDate, name: shiftType });
    const newShiftInfo = {
      start,
      end,
      name: shiftType,
      selectedDate,
    };
    setMultipleShiftInfo((previousMultipleShiftInfo) => {
      const newMultipleShiftInfo = new Map(previousMultipleShiftInfo);
      if (isSelected) {
        newMultipleShiftInfo.set(shiftInfoKey, newShiftInfo);
      } else {
        newMultipleShiftInfo.delete(shiftInfoKey);
      }
      return newMultipleShiftInfo;
    });
    const validationDetails = isSelected
      ? await validateShiftInfo({ shiftInviteInfo: newShiftInfo, selectedWorker })
      : undefined;

    setMultipleShiftValidationDetails((previousMultipleShiftValidationDetails) => {
      const newMultipleShiftValidationDetails = new Map(previousMultipleShiftValidationDetails);
      if (isDefined(validationDetails)) {
        newMultipleShiftValidationDetails.set(shiftInfoKey, validationDetails);
      } else {
        newMultipleShiftValidationDetails.delete(shiftInfoKey);
      }
      return newMultipleShiftValidationDetails;
    });
  };

  return {
    groupInviteSharedData,
    setGroupInviteSharedData,
    multipleShiftInfo,
    setMultipleShiftInfo,
    multipleShiftValidationDetails,
    setMultipleShiftValidationDetails,
    isMultiShiftInvitesEnabled,
    shiftTimes,
    validateAndUpdateShiftInvite,
    isValidatingShiftInfo,
    facilityTimeZone,
  };
}

export const [ShiftInviteProvider, useShiftInviteContext] = constate(useShiftInvite);
