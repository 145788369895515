import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { formatPhone } from "src/utils/strings";
import { Avatar, Stack } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { NearbyWorkerSearchResult, WorkerStatusLabel } from "./useGetNearbyWorkers";
import { ReactElement } from "react";
import { isDefined } from "@clipboard-health/util-ts";
import { useAgentProfilePicture } from "@src/appV2/ShiftBlock/hooks/useGetAgentProfilePicture";

interface NearbyWorkerResultProps {
  renderProps: React.HTMLAttributes<HTMLLIElement>;
  nearbyWorker: NearbyWorkerSearchResult;
}

export function NearbyWorkerResult(props: NearbyWorkerResultProps) {
  const { renderProps, nearbyWorker } = props;
  const { workerId, fullName, statusLabel, phoneNumber } = nearbyWorker;

  const WORKER_STATUS_ICONS: Record<WorkerStatusLabel, ReactElement> = {
    [WorkerStatusLabel.Favorite]: <StarIcon sx={{ color: "text.secondary" }} />,
    [WorkerStatusLabel.Restricted]: <WarningAmberIcon sx={{ color: "text.secondary" }} />,
  };

  const { data: agentProfileImage } = useAgentProfilePicture({ agentId: workerId });

  return (
    <li {...renderProps}>
      <Stack spacing={2} alignItems="center" direction="row">
        <Avatar src={agentProfileImage?.distribution_url}>
          {isDefined(statusLabel) ? (
            WORKER_STATUS_ICONS[statusLabel]
          ) : (
            <AccountCircleIcon sx={{ color: "text.secondary" }} />
          )}
        </Avatar>
        <Stack>
          <span>
            {fullName} {formatPhone(phoneNumber)}
          </span>
          <Stack sx={{ variant: "body2", color: "text.secondary" }}>
            {statusLabel === WorkerStatusLabel.Restricted && "Restricted Account"}
            {statusLabel === WorkerStatusLabel.Favorite && "Facility Favorite"}
          </Stack>
        </Stack>
      </Stack>
    </li>
  );
}
