import { Modal } from "antd";
import { getErrorMessage } from "../../utils/errors";
import { StyledDivider } from "./styles";
import { SUPPORT_CONTACT_FORM_URL } from "./constants";

export const handleWorkerRatingErrorMessageDisplay = (error: any) => {
  const isForbidden = error?.status === 403;
  return Modal.error({
    title: <b>{isForbidden ? "This professional can not be rated" : "Error rating worker"}</b>,
    content: (
      <div data-testid="worker-reviews-modal-error-content">
        <div>{getErrorMessage(error, "There is an error when creating rating performance.")}</div>
        <StyledDivider />
        <div>
          To add this professional to your facility favorite list please contact{" "}
          <a href={SUPPORT_CONTACT_FORM_URL} target="_blank">
            clipboard health support
          </a>
        </div>
      </div>
    ),
  });
};
