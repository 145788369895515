export enum NON_BOOKABLE_CRITERIA {
  // If a worker has an overlapping shift
  SHIFT_OVERLAPPING = "SHIFT_OVERLAPPING",
  // If the shift is an overworking shift
  SHIFT_OVERWORKING = "SHIFT_OVERWORKING",
  // If the shift is in the past
  SHIFT_IN_PAST = "SHIFT_IN_PAST",
  // If the worker does not have an approved license to work the shift
  WORKER_MISSING_LICENSE = "WORKER_MISSING_LICENSE",
  // If core requirements(requirements for booking at a non-hcf level in the QFA range) are missing. The worker cant book the shift
  WORKER_MISSING_DOCUMENTS = "WORKER_MISSING_DOCUMENTS",
  // If HCF requirements are missing, the worker can't book the shift.
  WORKER_MISSING_REQUIREMENTS = "WORKER_MISSING_REQUIREMENTS",
  // If the worker is a test account, they should not be able to book shifts at a non-test facility.
  // If the workplace is a test account but the worker is a non-test account
  WORKER_DISABLED_TEST_ACCOUNT = "WORKER_DISABLED_TEST_ACCOUNT",
  // If the worker cannot claim the shift
  WORKER_CANT_CLAIM_SHIFT = "WORKER_CANT_CLAIM_SHIFT",
  // Stripe not enabled
  WORKER_PAYMENTS_DISABLED = "WORKER_PAYMENTS_DISABLED",
  // If core requirements(requirements for booking at a non-hcf level in QFA range) are not missing but pending. The worker cant book the shift
  WORKER_PENDING_DOCUMENTS = "WORKER_PENDING_DOCUMENTS",
  // If the worker still needs to sign the latest IC agreement.
  WORKER_UNSIGNED_AGREEMENT = "WORKER_UNSIGNED_AGREEMENT",
  // If the worker is deactivated.
  WORKER_DEACTIVATED = "WORKER_DEACTIVATED",
  // If the worker account is under review.
  WORKER_ACCOUNT_IN_REVIEW = "WORKER_ACCOUNT_IN_REVIEW",
  // If the worker account is under review.
  FACILITY_SUSPENDED = "FACILITY_SUSPENDED",
}
