import { Button } from "src/designsystem/Button/Button";
import { useSelector } from "react-redux";
import { SessionType } from "src/modules/interface";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { FacilityStatusObj } from "src/utils/facility";
import { Link } from "react-router-dom";
import { getLocation } from "src/utils/routes";
import { BannerProps } from "src/designsystem/Banner/Banner.type";

export function useWorkplaceSignContractBanner() {
  const { user, profile, admin } = useSelector((state: SessionType) => state.session);

  if (profile.status !== FacilityStatusObj.UNSIGNED || user?.isSuspicious === true) {
    return null;
  }

  function trackEvent(action?: string) {
    if (user && !admin) {
      logEvent(HCF_USER_EVENTS.FACILITY_CONTRACT_REQUIREMENT_POPUP, {
        action,
        workplaceId: profile.userId,
        workplaceUserId: user._id,
        isEmailVerified: !!user.isEmailVerified,
        isSuspicious: !!user.isSuspicious,
        page: "Calendar",
      });
    }
  }

  const bannerProps: BannerProps = {
    title: <b>Finish signing up</b>,
    description: <>Review and sign our contract so that you can start posting shifts!</>,
    closable: false,
    action: (
      <Link
        to={getLocation("workplaceSignContract", {
          pathParams: { workplaceId: profile.userId },
        })}
        target="_blank"
        rel="noopener"
        color="primary"
        type="button"
      >
        <Button variant="primary" onClick={() => trackEvent("view contract via banner")}>
          <b>View contract</b>
        </Button>
      </Link>
    ),
    type: "info",
    "data-testid": "workplace-sign-contract-banner",
  };

  return {
    bannerProps,
  };
}
