import { UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import pluralize from "pluralize";

type ShiftInviteMissingFacilityDocumentsDialogProps = {
  modalState: UseModalState;
  selectedWorkerName: string;
  missingDocuments: string[];
  sendInviteWithSkipFacilityDocumentCheck: () => void;
};

export function ShiftInviteMissingFacilityDocumentsDialog(
  props: ShiftInviteMissingFacilityDocumentsDialogProps
) {
  const {
    modalState,
    selectedWorkerName,
    sendInviteWithSkipFacilityDocumentCheck,
    missingDocuments,
  } = props;
  const documentPlural = pluralize("document", missingDocuments.length);
  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => modalState.closeModal()}
      aria-labelledby="shift-invite-missing-facility-document-dialog-title"
    >
      <DialogTitle>
        {selectedWorkerName} is missing their {missingDocuments.join(", ")} {documentPlural}. You
        can proceed with this invite, but {selectedWorkerName} may not have{" "}
        {pluralize("this", missingDocuments.length)} {documentPlural} uploaded before the shift
        begins.
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={() => modalState.closeModal()}>
          Cancel Invite
        </Button>
        <Button variant="contained" onClick={() => sendInviteWithSkipFacilityDocumentCheck()}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
