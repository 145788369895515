import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { startOfDay } from "date-fns";
import { ShiftName } from "src/interface";
import { ShiftInviteQueryParams, useCreateShiftInvite } from "./useCreateShiftInvite";
import { NearbyWorkerSearchResult } from "./Autocomplete/useGetNearbyWorkers";
import { formatDate, formatTime } from "@clipboard-health/date-time";
import { SessionType } from "src/modules/interface";
import { type ShiftInviteDialogProps, ShiftInviteErrorDialog } from "./Dialogs";
import { logError, logEvent } from "src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { GroupShiftInviteMultiDatePickerCalendar } from "./GroupShiftInviteMultiDatePickerCalendar";
import { ShiftTypeTimeSelection } from "./ShiftTypeTimeSelection";
import { getShiftInfoKey, parseValidDateOrDefaultToToday } from "./utils";
import pluralize from "pluralize";
import { useToast } from "@src/appV2/lib";
import { GroupInviteToolTip } from "./GroupInviteToolTip";
import { DEFAULT_TIMEZONE } from "@src/constants/timezone";
import { blueGrey } from "@mui/material/colors";

export interface GroupShiftInviteDetailsProps {
  shiftInviteDialogProps: ShiftInviteDialogProps;
  setActiveStep: (step: number) => void;
  selectedWorker?: NearbyWorkerSearchResult;
  setSelectedWorker: (worker: NearbyWorkerSearchResult | undefined) => void;
}

export function GroupShiftInviteDetails(props: GroupShiftInviteDetailsProps) {
  const { selectedWorker, setSelectedWorker, setActiveStep, shiftInviteDialogProps } = props;
  const { workerType, modalState, shiftInfo: selectedShiftInfo } = shiftInviteDialogProps;

  const parsedSelectedDate = utcToZonedTime(
    parseValidDateOrDefaultToToday(selectedShiftInfo.start),
    selectedShiftInfo.timezone ?? DEFAULT_TIMEZONE
  );
  const initialSelectedDate = startOfDay(parsedSelectedDate);
  const {
    groupInviteSharedData,
    setGroupInviteSharedData,
    multipleShiftInfo,
    setMultipleShiftInfo,
    multipleShiftValidationDetails,
    setMultipleShiftValidationDetails,
    shiftTimes,
    validateAndUpdateShiftInvite,
    isValidatingShiftInfo,
    facilityTimeZone,
  } = useShiftInviteContext();
  const theme = useTheme();
  const { postShiftToMarketplace, workplaceId } = groupInviteSharedData;
  const { user, admin } = useSelector((state: SessionType) => state.session);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(initialSelectedDate);
  const shiftInviteErrorModalState = useModalState();

  const { mutateAsync: createShiftInvite, isLoading: isSaving } = useCreateShiftInvite();
  const { showSuccessToast } = useToast();

  const segmentProperties = {
    workplaceId,
    workerType,
    ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
    ...(selectedWorker ?? {}),
  };
  const workerId = selectedWorker?.workerId ?? "";

  async function sendInvite(queryParams: ShiftInviteQueryParams = {}) {
    const shiftInfo = [...multipleShiftInfo.values()][0];
    const { end: shiftEndTime, start: shiftStartTime, name } = shiftInfo;
    try {
      const shiftInviteRequest = {
        workerId,
        workplaceId,
        shiftDetails: {
          start: zonedTimeToUtc(shiftStartTime, facilityTimeZone),
          end: zonedTimeToUtc(shiftEndTime, facilityTimeZone),
          agentReq: workerType,
          name,
          tmz: facilityTimeZone,
        },
        postShiftToMarketplace,
      };

      await createShiftInvite({
        shiftInviteRequest,
        queryParams,
      });
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "SENT",
        ...segmentProperties,
      });
      modalState.closeModal();
      showSuccessToast(
        `Your invite to ${selectedWorker?.fullName}, for ${formatDate(
          shiftStartTime
        )} from ${formatTime(shiftStartTime)} to ${formatTime(
          shiftEndTime
        )} has been sent! You can view and manage this invite on your calendar.`
      );
    } catch (error: unknown) {
      logError("Error while creating the shift invite", {
        error,
      });

      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "SEND_ERROR",
        ...segmentProperties,
      });

      shiftInviteErrorModalState.openModal();
    }
  }

  const shouldSendSingleInvite =
    multipleShiftInfo.size === 1 && multipleShiftValidationDetails.size === 0;

  if (!isDefined(shiftTimes)) {
    return null;
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <Text>Select date(s)</Text>
          <GroupInviteToolTip />
        </Stack>
        <GroupShiftInviteMultiDatePickerCalendar
          onChange={(value) => {
            setSelectedDate(value);
          }}
          showDaysOutsideCurrentMonth
          fixedWeekNumber={6}
          minDate={startOfDay(utcToZonedTime(new Date(), facilityTimeZone))}
          disableHighlightToday
          selectedDate={selectedDate}
          highlightedDays={[
            ...new Set(
              [...multipleShiftInfo.values()].map((shiftInfo) => startOfDay(shiftInfo.start))
            ),
          ]}
        />
        <Stack spacing={1}>
          <Text>
            Shift Type{" "}
            {isDefined(selectedDate) ? `- Select shift types for ${formatDate(selectedDate)}` : ""}
          </Text>
          <Stack spacing={2} direction="column">
            {Object.values(ShiftName).map((type) => (
              <ShiftTypeTimeSelection
                key={type}
                shiftType={type}
                shiftTime={shiftTimes[type]}
                selectedDate={selectedDate}
                shiftInfo={
                  isDefined(selectedDate)
                    ? multipleShiftInfo.get(getShiftInfoKey({ date: selectedDate, name: type }))
                    : undefined
                }
                onChange={(shiftInfo) => {
                  validateAndUpdateShiftInvite({
                    selectedShiftInfo: shiftInfo,
                    selectedDate,
                    selectedWorker,
                  });
                }}
              />
            ))}
          </Stack>
        </Stack>
        <Stack
          sx={{
            alignItems: "center",
            bgcolor: () => {
              return postShiftToMarketplace ? blueGrey[100] : theme.palette.common.white;
            },
            border: "2px solid",
            borderColor: postShiftToMarketplace
              ? theme.palette.primary.dark
              : theme.palette.grey[400],
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            justifyContent: "space-between",
            transition: "background-color 0.3s, border-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: postShiftToMarketplace ? blueGrey[200] : theme.palette.action.hover,
            },
          }}
          direction="row"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={postShiftToMarketplace}
                onChange={() =>
                  setGroupInviteSharedData((previousGroupInviteSharedData) => ({
                    ...previousGroupInviteSharedData,
                    postShiftToMarketplace: !previousGroupInviteSharedData.postShiftToMarketplace,
                  }))
                }
              />
            }
            label={
              <Text
                sx={{
                  color: postShiftToMarketplace ? theme.palette.primary.dark : "inherit",
                }}
              >
                Post any declined and expired invites as shifts!
              </Text>
            }
          />
          <Tooltip
            placement="top"
            title="If the professional declines any of these invites, or they expire, post the shifts to my favorite workers, and then to other available professionals. You can view and manage your invites, and see when they will expire on your calendar."
          >
            <Box
              sx={{
                textDecoration: "underline",
                color: theme.palette.primary.dark,
                cursor: "pointer",
              }}
            >
              What's this?
            </Box>
          </Tooltip>
        </Stack>
        <Divider sx={{ background: theme.palette.primary.dark }} />
        <Stack spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
          {Object.values(ShiftName).map((shiftName) => {
            const shiftCount = [...multipleShiftInfo.keys()].filter((shiftInfoKey) =>
              shiftInfoKey.startsWith(shiftName)
            ).length;
            return (
              <Text variant="subtitle2" key={shiftName}>
                {shiftName.toUpperCase()} {pluralize("Invite", shiftCount)}: {shiftCount}
              </Text>
            );
          })}
          <Stack spacing={1} direction="row" justifyContent="flex-end">
            <Button
              onClick={() => {
                setActiveStep(0);
                setSelectedWorker(undefined);
                logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
                  action: "NOT_SENT",
                  ...segmentProperties,
                });
                setMultipleShiftInfo(new Map());
                setMultipleShiftValidationDetails(new Map());
              }}
              variant="outlined"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              Back
            </Button>
            <Button
              onClick={async () => {
                if (shouldSendSingleInvite) {
                  await sendInvite();
                } else {
                  setActiveStep(2);
                }
              }}
              variant="contained"
              startIcon={
                isValidatingShiftInfo && <CircularProgress size={18} sx={{ color: "inherit" }} />
              }
              disabled={isSaving || multipleShiftInfo.size === 0 || isValidatingShiftInfo}
            >
              {isValidatingShiftInfo
                ? "Validating"
                : shouldSendSingleInvite
                ? "Send Invite"
                : `Review ${pluralize("Invite", multipleShiftInfo.size)}`}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <ShiftInviteErrorDialog modalState={shiftInviteErrorModalState}></ShiftInviteErrorDialog>
    </>
  );
}
