import { enqueueSnackbar, closeSnackbar, type SnackbarKey } from "notistack";

export function showSuccessToast(message: string, onClick?: () => void) {
  return enqueueSnackbar({
    message,
    variant: "success",
    onClick,
  });
}

export function showInfoToast(message: string) {
  return enqueueSnackbar({
    message,
    variant: "info",
  });
}

export function showErrorToast(message: string) {
  return enqueueSnackbar({
    message,
    variant: "error",
  });
}

export function showWarningToast(message: string) {
  return enqueueSnackbar({
    message,
    variant: "warning",
  });
}

export function closeToast(toastKey: SnackbarKey) {
  closeSnackbar(toastKey);
}
