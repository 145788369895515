import request from "superagent";
import { logError } from "src/appV2/lib/analytics";
import { getAuthHeader } from "../../utils/superagent";
import { WORKER_RETRY_COUNT, WORKER_TIMEOUT_DURATION } from "../constants";
import WorkerType from "../interfaces/WorkerType";
import WorkPlaceType from "../interfaces/WorkPlaceType";
import { environmentConfig } from "@src/appV2/environment";

export async function fetchWorkerAndWorkplaceTypes(): Promise<
  | {
      workerTypes: WorkerType[];
      workPlaceTypes: WorkPlaceType[];
    }
  | undefined
> {
  try {
    const { body } = await request
      .get(`${environmentConfig.REACT_APP_BASE_API_URL}/workerAndWorkPlaceTypes`)
      .set(await getAuthHeader())
      .send()
      .timeout(WORKER_TIMEOUT_DURATION)
      .retry(WORKER_RETRY_COUNT);
    return body.data;
  } catch (error) {
    logError("Error in fetchWorkerAndWorkplaceTypes - Unable to fetch worker and workplace types", {
      error,
    });
  }
}
