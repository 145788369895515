import { isValid, parseISO } from "date-fns";

interface GetShiftInfoKeyParams {
  date: Date;
  name: string;
}

export function getShiftInfoKey(getShiftInfoKeyParams: GetShiftInfoKeyParams): string {
  const { date, name } = getShiftInfoKeyParams;
  return `${name}-${date.toISOString()}`;
}

export function parseValidDateOrDefaultToToday(date: string): Date {
  return isValid(parseISO(date)) ? parseISO(date) : new Date();
}
