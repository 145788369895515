import { z } from "zod";
import { qualificationSchema } from "../Qualifications/types";

const workplaceTypeAttributesSchema = z.object({
  name: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const workplaceTypeQualificationsSchema = z.array(
  z.object({
    type: z.literal("qualification"),
    id: z.string(),
  })
);

const workplaceTypeRelationshipsSchema = z.object({
  qualifications: z.object({
    data: workplaceTypeQualificationsSchema,
  }),
});

const workplaceTypeSchema = z.object({
  type: z.literal("workplace-type"),
  id: z.string(),
  attributes: workplaceTypeAttributesSchema,
  relationships: workplaceTypeRelationshipsSchema,
});

export const workplaceTypesResponseSchema = z.object({
  data: z.array(workplaceTypeSchema),
  included: z.array(qualificationSchema),
});

export const workplaceTypeResponseSchema = z.object({
  data: workplaceTypeSchema,
  included: z.array(qualificationSchema),
});

export const workplaceTypeCreateAttributesSchema = workplaceTypeAttributesSchema.omit({
  createdAt: true,
  updatedAt: true,
});

export const workplaceTypeCreateRequestSchema = z.object({
  data: z.object({
    type: z.literal("workplace-type"),
    attributes: workplaceTypeCreateAttributesSchema,
    relationships: workplaceTypeRelationshipsSchema,
  }),
});

export const workplaceTypeCreateParamsSchema = workplaceTypeCreateAttributesSchema.merge(
  z.object({
    qualifications: z.array(
      z.object({
        id: z.string(),
      })
    ),
  })
);

export const workplaceTypeEditParamsSchema = z
  .object({
    id: z.string(),
  })
  .merge(workplaceTypeCreateParamsSchema);

export const workplaceTypeEditRequestSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("workplace-type"),
    attributes: workplaceTypeCreateAttributesSchema,
    relationships: workplaceTypeRelationshipsSchema,
  }),
});

export type WorkplaceTypeCreateParams = z.infer<typeof workplaceTypeCreateParamsSchema>;
export type WorkplaceTypeEditParams = z.infer<typeof workplaceTypeEditParamsSchema>;

export type WorkplaceType = z.infer<typeof workplaceTypeSchema>;
export type WorkplaceTypesResponse = z.infer<typeof workplaceTypesResponseSchema>;
export type WorkplaceTypeResponse = z.infer<typeof workplaceTypeResponseSchema>;
