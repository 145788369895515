import { useState } from "react";
import { Box, Stack, ToggleButton } from "@mui/material";
import { TimeValidationError } from "@mui/x-date-pickers";
import { utcToZonedTime } from "date-fns-tz";
import { isDefined } from "@clipboard-health/util-ts";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";

import { DefaultShiftTimes } from "@src/containers/facilityDashboard/ShiftCalendar/api";
import {
  convertShiftTimeToDateTime,
  normalizeShiftEndTime,
} from "@src/containers/facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { ShiftName } from "@src/appV2/ShiftBlock/types/shift";
import { getShiftInfoKey } from "./utils";
import { ShiftInviteInfo } from "./ShiftInviteReview";
import { useShiftInviteContext } from "./Context/shiftInviteContext";
import { differenceInHours, isSameDay } from "date-fns";
import { Text } from "@clipboard-health/ui-react";
import { shiftTypeColorMap } from "./types";

export interface ShiftTypeTimeSelectionProps {
  shiftType: ShiftName;
  onChange?: (value: SelectedShiftInfo) => void;
  shiftTime: DefaultShiftTimes[keyof DefaultShiftTimes];
  selectedDate?: Date;
  shiftInfo: ShiftInviteInfo | undefined;
}

export interface SelectedShiftInfo {
  shiftType: ShiftName;
  start?: Date;
  end?: Date;
  isSelected: boolean;
}

export function ShiftTypeTimeSelection(props: ShiftTypeTimeSelectionProps) {
  const { shiftType, onChange, shiftTime, selectedDate, shiftInfo } = props;
  const { facilityTimeZone } = useShiftInviteContext();
  const { startTime, endTime } = isDefined(shiftInfo)
    ? { startTime: shiftInfo.start, endTime: shiftInfo.end }
    : convertShiftTimeToDateTime({ shiftTime, startDate: selectedDate });

  const [timeErrors, setTimeErrors] = useState<Map<string, TimeValidationError | "invalidRange">>();
  const timeError =
    isDefined(timeErrors) && isDefined(selectedDate)
      ? timeErrors.get(getShiftInfoKey({ date: selectedDate, name: shiftType }))
      : "";
  const isSelected = isDefined(shiftInfo);
  function onShiftInfoChange(params: Partial<SelectedShiftInfo>) {
    onChange?.({
      shiftType,
      start: startTime,
      end: endTime,
      isSelected,
      ...params,
    });
  }

  const duration = differenceInHours(endTime, startTime, { roundingMethod: "ceil" });

  return (
    <Stack spacing={1} direction="row">
      <Box sx={{ minWidth: "56px", maxWidth: "56px" }}>
        <ToggleButton
          value="check"
          color={shiftTypeColorMap[shiftType]}
          selected={isSelected}
          onChange={() => {
            setTimeErrors((prevTimeErrors) => {
              const newTimeErrors = new Map(prevTimeErrors);
              if (isDefined(selectedDate)) {
                newTimeErrors.delete(getShiftInfoKey({ date: selectedDate, name: shiftType }));
              }
              return newTimeErrors;
            });
            onShiftInfoChange({ isSelected: !isSelected, start: startTime, end: endTime });
          }}
          sx={{ width: "100%", height: "100%" }}
          disabled={!isDefined(selectedDate)}
        >
          {shiftType.toUpperCase()}
        </ToggleButton>
      </Box>
      <Box sx={{ position: "relative", width: "100%" }}>
        <MultiInputTimeRangeField
          value={[startTime, endTime]}
          slotProps={{
            textField: ({ position }) => ({
              label:
                position === "start"
                  ? "Start time"
                  : isSameDay(startTime, endTime)
                  ? "End time"
                  : "End time (next day)",
              helperText:
                timeError === "minTime" && position === "start" ? "Time can't be in the past." : "",
            }),
          }}
          onChange={([start, end]) => {
            const newEnd =
              isDefined(start) && isDefined(end) ? normalizeShiftEndTime(start, end) : end;
            onShiftInfoChange({ start: start ?? undefined, end: newEnd ?? undefined });
          }}
          disableIgnoringDatePartForTimeValidation={true}
          minTime={isSelected ? utcToZonedTime(new Date(), facilityTimeZone) : undefined}
          onError={([startError, endError]) => {
            setTimeErrors((prevTimeErrors) => {
              const newTimeErrors = new Map(prevTimeErrors);
              if (isDefined(selectedDate)) {
                newTimeErrors.set(
                  getShiftInfoKey({ date: selectedDate, name: shiftType }),
                  startError ?? endError
                );
              }
              return newTimeErrors;
            });
          }}
          disabled={!isSelected}
        />
        {!isSelected && (
          <Box
            onClick={() =>
              onShiftInfoChange({ isSelected: !isSelected, start: startTime, end: endTime })
            }
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
              backgroundColor: "transparent",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
          />
        )}
      </Box>
      {duration > 17 && <Text alignContent={"center"}>({`${duration} hours`}) </Text>}
    </Stack>
  );
}
