export enum CbhFeatureFlag {
  ALLOW_ADDITIONAL_CANCELLATION_OPTIONS = "allow-additional-shift-cancellation-options",
  MASS_SHIFT_UPLOAD = "mass-shift-upload",
  SHOULD_SHOW_WORKER_ETA = "should-show-worker-eta",
  ENABLE_NOTIFICATION_PREFERENCES = "hcp-notification-preferences-v2-active",
  // Note: This is a temporary key and will be remove once feature is release for all the users.
  BASE_RATE_PAY_FILTERS = "base-rate-pay-filters",
  BASE_RATE_MULTIPLE_PAY_FILTERS = "base-rate-multiple-pay-filters",
  FACILITY_USER_SUBMIT_SENT_HOME = "facility-user-submit-sent-home",
  NLC_STATES = "nlc-states",
  PERSONAL_ID_SUBTYPES = "personal-id-subtypes",
  HCP_MINIMUM_CHARGE_RATES = "hcp-minimum-charge-rates",
  WORKER_RATINGS_CAROUSEL = "worker-ratings-carousel",
  PRIORITY_ACCESS = "priority-access",
  SUPPORT_LINKS = "support-links",
  MANAGE_NFC_TAGS = "manage-nfc-tags",
  CALIFORNIA_TIMESHEET = "california-timesheet",
  WORKER_SERVICE_FOR_ADMIN_PANEL = "use-worker-service-for-admin-panel",
  WORKER_SERVICE_MIGRATED_API_MAP = "worker-service-migrated-api-map-for-admin-panel",
  RECOMMENDED_RATE_EXPERIMENT = "recommended-rate-experiment",
  VARIABLE_CHARGE_STANDARD = "variable-charge-standard",
  RECOMMENDED_RATE_FOR_CHARGE_BLOCKED_FACILITIES = "recommended-rates-for-charge-blocked-facilities",
  TIME_NEGOTIATION_ACTIVATION = "shift-time-negotiation-activation",
  RATE_NEGOTIATION_ACTIVATION = "rate-negotiation-activation",
  RATE_NEGOTIATION_COMMS_EMAIL = "rate-negotiation-comms-email",
  RATE_NEGOTIATION_COMMS_SMS = "rate-negotiation-comms-sms",
  MY_ACCOUNT_PAGE = "myaccount-page",
  RATE_NEGOTIATION_LIMITS = "rate-negotiation-limits",
  RATE_NEGOTIATION_DISPLAY_WORKER_RATING = "rate-negotiation-display-worker-rating",
  MARKETING_CONVERSION_QUALIFICATIONS_WHITELIST = "marketing-conversion-qualifications-whitelist",
  NEW_USERS_PAGE = "new-users-page",
  BULK_UPDATE_EMPLOYEE_ROLES = "bulk-update-employee-roles",
  NOTIFICATION_CENTER_ACTIVE = "notification-center-hcf-web",
  NOTIFICATION_CENTER_COMMS_ASSIGNED = "notification-center-comms-assigned",
  NOTIFICATION_CENTER_COMMS_UNASSIGNED = "notification-center-comms-unassigned",
  SENDBIRD_CONCURRENT_CONNECTION_OPTIMISATION = "send-bird-concurrent-connection-optimisation",
  MAKE_RATING_FEEDBACK_REQUIRED = "make-rating-feedback-required",
  SHOULD_SHOW_LIVE_MAP_VIEW = "show-live-worker-eta-map",
  AUTO_EXPAND_SHIFTS_PANEL_IN_CALENDAR_PAGE = "auto-expand-shifts-panel-in-calendar-page",
  WORKPLACE_ONBOARDING_STEPS_AB_TESTING = "workplace-onboarding-steps-a-b-testing",
  POST_SHIFT_OPTIONS_AB_TESTING = "post-shift-options-a-b-testing",
  HCF_ONBOARDING = "hcf-onboarding",
  WORKPLACE_BLOCK_SHIFTS_TYPE_FORMS = "workplace-assignment-typeforms",
  USP_CLIENT_SETTINGS = "usp-client-settings",
  WORKPLACE_SELF_ONBOARDING_CTA_COPY = "workplace-self-onboarding-cta-copy",
  IN_APP_CHAT_LIMITED_DAYS_AFTER_BOOKED_SHIFT = "in-app-chat-limited-days-after-last-booked-shift",
  SHOW_RATE_NEGOTIATION_CONTROL_TOGGLE = "show-rate-negotiation-control-toggle",
  BULK_SHIFT_POSTING = "bulk-shift-posting",
  ATTENDANCE_RATE_CONFIG = "attendance-rate-config",
  VARIABLE_CHARGE_REVAMPED_ACCESS_CONTROL = "variable-charge-revamped-access-control",
  WORKPLACE_SELF_SIGNUP = "workplace-self-signup",
  REMOVE_ADDED_MANUAL_PAY_WHEN_MANUAL_CHARGE = "remove-added-manual-pay-when-manual-charge",
  AVAILABLE_WORKERS_SHIFT_INFO_TEXT = "available-workers-shift-info-text",
  MAPLE_INTEGRATION = "maple-integration",
  UI_STRIPE_TRANSFERS = "ui-stripe-transfers",
  ADMIN_APP_HIDE_PAYROLL_TABLE = "admin-app-hide-payroll-table",
  WORKER_BLOCK_BOOKING = "worker-block-booking",
  SHOULD_SHOW_CONTRACT_TERMS = "should-show-contract-terms",
  FIREBASE_24_HOURS_EXPIRY_ENABLED = "2023-10-sparta-rollout-expire-firebase-token-in-24-hours",
  LD_UPGRADE_TESTING_WITH_CONTEXT = "2023-11-07-ld-upgrade-testing-with-context",
  EDIT_SHIFT_TIME_RESTRICTION = "2023-12-facilitate-edit-shift-time-restriction",
  VIEW_SHIFT_CHARGE_RATE = "2023-12-facilitate-view-shift-charge-rate",
  MAXIMUM_ALLOWED_WORKPLACE_CHARGE_RATES = "2024-01-facilitate-maximum-allowed-workplace-charge-rates",
  SHIFT_INVITE_CONFIGURATION = "2024-01-invaluables-configure-shift-invites",
  ROLLOUT_CHAT_CREATION = "2024-01-facilitate-rollout-chat-creation",
  ROLLOUT_WORKER_TYPE_MANAGEMENT = "2024-01-facilitate-rollout-worker-type-management",
  ROLLOUT_BLOCK_BOOKING = "2024-02-facilitate-rollout-block-shift-booking",
  ROLLOUT_MULTI_SHIFT_INVITES = "2024-03-invaluables-rollout-multi-shift-invites",
  ROLLOUT_QUIZ_V2_FACILITY_RULES_AI = "2024-02-action-rollout-quiz-v2-facility-rules-ai",
  SHOW_UPDATED_DNR_COPY = "2024-05-value-show-updated-dnr-copy",
  SOLVE_UNPAID_BREAKS_ROLLOUT_STAGE_WITH_TARGETING = "2024-02-team-x-solve-worked-unpaid-breaks-rollout",
  ENABLE_SHIFT_SPECIFICATION = "2024-06-facilitate-add-shift-specification",
  UNFILLED_PAST_SHIFT_DELETION = "2024-07-team-facilitate-rollout-unfilled-past-shift-deletion",
  MUI_MIGRATION = "2024-08-05-team-facilitate-rollout-mui-migration",
  WORKPLACE_REFERRALS = "2023-10-facilitate-rollout-workplace-referrals",
  HOME_HEALTH_ENABLED = "2024-01-home-health-HCF-HHA-enabled",
  HOME_HEALTH_PAY_RATES = "2024-03-home-health-default-pay-rates-per-visit-type",
  STREAKS_PRE_REWARD = "2024-03-core-services-streaks-pre-rewards",
  HOME_HEALTH_WORKER_CHAT_ENABLED = "2024-03-home-health-worker-chat-enabled",
  HOME_HEALTH_FOOTER_URLS = "2024-05-home-health-hcf-footer-urls",
  HOME_HEALTH_BONUS_PAYMENT = "2024-05-home-health-bonus-payment",
  HCP_SOFT_DELETION = "2024-06-sparta-rollout-hcp-soft-deletion",
  SHOW_SHIFT_BLOCK_LABEL_ALTERNATE_COPY = "2024-06-facilitate-show-shift-block-label-alternate-copy",
  MAGIC_POINTS = "2024-06-core-services-magic-points",
  HOME_HEALTH_ONBOARDING_ENABLED = "2024-07-home-health-onboarding-enabled",
  SHOW_AVAILABLE_WORKERS_CONFIG = "notify-available-workers-about-shift-creation",
  ALLOW_FACILITIES_TO_SET_MAX_CONSECUTIVE_HOURS = "2024-07-allow-facilities-to-set-max-consecutive-hours-and-hours",
  HOME_HEALTH_PT_ENABLED = "2024-08-home-health-pt-enabled",
  WORKER_DOCUMENTS_PAGE_V2_ENABLED = "2024-08-team-alchemy-worker-documents-page-v2-rollout",
}
