import { UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

type ShiftInviteOverworkDialogProps = {
  modalState: UseModalState;
  selectedWorkerName: string;
  sendInviteWithAllowedOverWorking: () => void;
};

export function ShiftInviteOverworkDialog(props: ShiftInviteOverworkDialogProps) {
  const { modalState, selectedWorkerName, sendInviteWithAllowedOverWorking } = props;
  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => modalState.closeModal()}
      aria-labelledby="shift-invite-overwork-dialog-title"
    >
      <DialogTitle>
        Sending {selectedWorkerName} this invite may result in them having more than 16.5
        consecutive hours of shifts booked. Are you sure you want to invite this professional?
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={() => modalState.closeModal()}>
          Cancel Invite
        </Button>
        <Button variant="contained" onClick={() => sendInviteWithAllowedOverWorking()}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
