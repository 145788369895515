import { UseGetQueryOptions, useGetQuery } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { z } from "zod";

const agentProfilePictureResponseSchema = z.object({
  distribution_url: z.string(),
});

interface GetAgentProfilePictureParams {
  agentId: string;
}
export type AgentProfilePictureResponse = z.infer<typeof agentProfilePictureResponseSchema>;

export function useAgentProfilePicture(
  params: GetAgentProfilePictureParams,
  options: UseGetQueryOptions<AgentProfilePictureResponse> = {}
) {
  const { agentId } = params;

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/${agentId}/profile-image-distribution-url`,
    responseSchema: agentProfilePictureResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_PROFILE_PICTURE_FAILURE,
    },
    // It's ok to have a 404 here, however, if we don't set this to false,
    // this hook will throw and cause a global 404 error
    useErrorBoundary: false,
    ...options,
  });
}
